import { gql, useLazyQuery } from '@apollo/client'
import { Search2Icon } from '@chakra-ui/icons'
import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputRightElement,
	Spinner,
	Text,
	useBoolean,
	useOutsideClick,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { SingleValue } from 'react-select'

import { FC, useRef, useState, useEffect } from 'react'

import CreatePatientDrawer from '~/components/CreatePatientDrawer'
import {
	PersonsSearch,
	PersonsSearch_personsSearch,
	PersonsSearchVariables,
} from '~/components/__generated__/PersonsSearch'
import { SearchFieldOptions, SearchFieldPlaceholders } from '~/constants'

import { joinValues } from './helpers'
import SelectDropdown from './ui/SelectDropdown'

/***
 *
 * Queries & Mutations
 *
 ***/
export const personsSearchQuery = gql`
	query PersonsSearch($where: JSON) {
		personsSearch(where: $where) {
			id
			first_name
			middle_name
			last_name
			email
			phone
			twilio_conversation_sid
		}
	}
`

interface SearchBarProps {
	chatView?: boolean
	onOpenSearchPerson?: (person: PersonsSearch_personsSearch) => void
}

/***
 *
 * Search Bar component
 *
 ***/
const SearchBar: FC<SearchBarProps> = ({ chatView, onOpenSearchPerson }) => {
	const searchResultRef = useRef(null)
	const [showSearchResult, setShowSearchResult] = useBoolean()
	const [showNoResult, setShowNoResult] = useBoolean(false)

	const [searchValue, setSearchValue] = useState<string>()
	const [searchField, setSearchField] = useState<SingleValue<{ label: string; value: string }>>(
		SearchFieldOptions[0]
	)
	const [searchQuery, setSearchQuery] = useState<string>('')

	const [searchPersons, { data, loading, updateQuery }] = useLazyQuery<
		PersonsSearch,
		PersonsSearchVariables
	>(personsSearchQuery, {
		fetchPolicy: 'network-only',
		onCompleted: data => {
			searchValue ? setShowSearchResult.on() : setShowSearchResult.off()
			!data.personsSearch.length ? setShowNoResult.on() : setShowNoResult.off()
		},
	})

	const personsSearchResult =
		(data?.personsSearch?.filter(Boolean) as PersonsSearch_personsSearch[]) ?? []
	const showResultDropdown = !loading && personsSearchResult.length > 0

	const handleSearch = (value?: string) => {
		if (!searchField || !value) {
			setSearchValue('')
			setSearchQuery('')
			setShowSearchResult.off()
			return
		}

		const searchQuery = `${searchField.value}:${value}`
		setSearchValue(value)
		setSearchQuery(searchQuery)
	}

	useOutsideClick({
		ref: searchResultRef,
		handler: () => setShowSearchResult.off(),
	})

	useEffect(() => {
		if (searchValue === undefined) return

		if (searchValue == '') {
			updateQuery?.(() => ({
				personsSearch: [],
			}))

			return
		}

		const fetchLeads = setTimeout(() => {
			searchPersons({ variables: { where: { search: searchQuery } } })
		}, 500)

		return () => clearTimeout(fetchLeads)
	}, [searchQuery])

	const handleClickSearchItem = (person: PersonsSearch_personsSearch) => {
		if (!chatView && !onOpenSearchPerson) {
			return navigate(`/person/${person.id}`)
		}
		if (onOpenSearchPerson instanceof Function) {
			onOpenSearchPerson(person)
			return setShowSearchResult.off()
		}
		return setShowSearchResult.off()
	}

	const barStyles = {
		flexWrap: 'wrap',
		align: 'center',
		position: 'relative',
	}

	const chatViewBarStyles = {
		padding: '0 15px',
		width: '100%',
		position: 'relative',
	}

	return (
		<Flex sx={chatView ? chatViewBarStyles : barStyles}>
			<Box sx={{ minWidth: '8em' }}>
				<SelectDropdown
					onFocus={() => setShowSearchResult.off()}
					options={SearchFieldOptions}
					styles={{
						control: () => ({
							boxShadow: 'none',
							borderTopRightRadius: 0,
							outline: 'none',
							borderBottomRightRadius: 0,
							backgroundColor: '#CBD5E0',
							borderColor: '#fff',
							'&:focus': {
								boxShadow: 'none',
								outline: 'none',
								border: 'none',
							},
						}),

						indicatorSeparator: () => ({
							width: 0,
						}),

						dropdownIndicator: () => ({
							color: '#2D3748',
						}),

						singleValue: () => ({
							fontSize: '1.1em',
							fontWeight: 700,
							color: '#2D3748',
						}),
					}}
					defaultValue={searchField}
					isSearchable={false}
					variant='small'
					placeholder='Search field'
					onChange={selected => {
						const option = selected as SingleValue<{ label: string; value: string }>

						setSearchField(option)
						setSearchValue('')
					}}
				/>
			</Box>
			<Box sx={{ position: chatView ? 'static' : 'relative', width: chatView ? '100%' : 'auto' }}>
				<InputGroup
					sx={{
						width: chatView ? '100%' : '18em',
					}}
				>
					<Input
						borderTopLeftRadius={0}
						borderBottomLeftRadius={0}
						borderColor='white'
						height='38px'
						color={chatView ? 'black' : 'gray.600'}
						type='text'
						placeholder={
							searchField?.value ? SearchFieldPlaceholders[searchField?.value] : 'Enter keyword'
						}
						bg={chatView ? '#f0f2f5' : 'white'}
						value={searchValue}
						onChange={evt => handleSearch(evt.target.value)}
						sx={{
							boxShadow: 'none',
							outline: 'none',
							'&:focus': {
								border: 'none',
								boxShadow: 'none',
								outline: 'none',
							},
						}}
					/>
					<InputRightElement cursor='default'>
						<Search2Icon color='gray.600' />
					</InputRightElement>
				</InputGroup>

				<Box
					ref={searchResultRef}
					visibility={showSearchResult || loading ? 'visible' : 'hidden'}
					position='absolute'
					w={chatView ? '100%' : '26em'}
					mt='0.3em'
					bg='white'
					shadow='md'
					py='0.5em'
					right='0em'
					roundedBottom='md'
					zIndex='10'
					maxH='30em'
					overflowY='auto'
				>
					{loading && (
						<Flex justify='center' gap='1em' p='1em'>
							<Spinner />
							<Text color='gray.500' fontSize='sm'>
								Loading
							</Text>
						</Flex>
					)}

					{showNoResult && searchValue && (
						<Flex justify='center' gap='1em' p='1em'>
							<Text color='gray.500' fontSize='sm'>
								No matching results
							</Text>
						</Flex>
					)}

					{showResultDropdown &&
						personsSearchResult.map(person => (
							<Box
								key={person?.id}
								animation='ease-in'
								px='1em'
								py='0.5em'
								cursor='pointer'
								_hover={{ bg: 'gray.100' }}
								onClick={() => handleClickSearchItem(person)}
							>
								<Text fontWeight='semibold' color='gray.600'>
									{joinValues(person?.first_name, person?.middle_name, person?.last_name)}
								</Text>
								<Text color='gray.500' fontSize='sm'>
									{person?.email}
								</Text>
								<Text color='gray.500' fontSize='sm'>
									{person?.phone}
								</Text>
							</Box>
						))}
				</Box>
			</Box>
			{!chatView ? <CreatePatientDrawer /> : null}
		</Flex>
	)
}

export default SearchBar
