import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Input,
	FormControl,
	FormLabel,
	Box,
	Flex,
	FormErrorMessage,
	RadioGroup,
	Stack,
	Radio,
	Text,
	Spinner,
	Tag,
	useDisclosure,
} from '@chakra-ui/react'
import { FormikProps, useFormik } from 'formik'
import { navigate } from 'gatsby'
import { PhoneNumberUtil } from 'google-libphonenumber'
import moment from 'moment'
import { PhoneInput } from 'react-international-phone'
import { MultiValue, SingleValue } from 'react-select'
import * as Yup from 'yup'

import React, { useEffect, useState } from 'react'

import DatePickerInput from '~/components/DatePicker'
import {
	dataToSelectOptions,
	DentallyEthnicityTypes,
	DentallyGenderEnum,
	DentallyPreferredPhoneArray,
	DentallyTitleEnum,
	DentallyTitleEnumArray,
	MarketingConsentArray,
} from '~/components/Dentally/helpers'
import {
	dentallyPaymentPlansMutation,
	dentallySitesMutation,
	getPractitionersMutation,
} from '~/components/forms/CreatePatientDentally/CreateDentallyPatientForm'
import { CreatePatientFormValues } from '~/components/forms/CreatePatientDentally/CreatePatientFormWrapper'
import {
	GetPaymentPlans,
	GetPaymentPlans_getPaymentPlans_payment_plans,
} from '~/components/forms/CreatePatientDentally/__generated__/GetPaymentPlans'
import {
	GetPractitioners,
	GetPractitioners_getPractitioners_practitioners,
	GetPractitionersVariables,
} from '~/components/forms/CreatePatientDentally/__generated__/GetPractitioners'
import {
	GetSites,
	GetSites_getSites_sites,
} from '~/components/forms/CreatePatientDentally/__generated__/GetSites'
import {
	CreateTrackerDentallyPatient,
	CreateTrackerDentallyPatientVariables,
} from '~/components/forms/__generated__/CreateTrackerDentallyPatient'
import { onError } from '~/components/helpers'
import { clinicsListQuery } from '~/components/kanban/Board'
import { ClinicsList } from '~/components/kanban/__generated__/ClinicsList'
import Button from '~/components/ui/Button'
import SelectDropdown, { PeopleOptionFactory } from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'
import { AssigneeTypes } from '~/constants'
import { Inquiries, Inquiries_inquiries } from '~/inputs/__generated__/Inquiries'
import { ListUsers, ListUsers_users, ListUsersVariables } from '~/inputs/__generated__/ListUsers'
import { listUsersQuery } from '~/inputs/assignees'
import { inquiryListQuery } from '~/inputs/inquiries'
import UserView from '~/views/user'

import { createTrackerDentallyPatientFields } from '../../../__generated__/globalTypes'

export const createTrackerDentallyPatientMutation = gql`
	mutation CreateTrackerDentallyPatient($data: createTrackerDentallyPatientFields!) {
		createTrackerDentallyPatient(data: $data) {
			id
			created_at
			updated_at
			phone
			email
			first_name
			last_name
			dentally_uuid
			dentally_person_id
		}
	}
`

interface CreatePatientFormProps {
	goToNext: () => void
	goToPrevious: () => void
	defaultFormValues: CreatePatientFormValues | null
}

interface CreatePatientFormInnerProps {
	goToPrevious: () => void
	formik: FormikProps<createTrackerDentallyPatientFields>
}

/***
 *
 * Appointment Form Component
 *
 ***/
const CreatePatientFormInner: React.FC<CreatePatientFormInnerProps> = ({
	goToPrevious,
	formik,
}) => {
	const [dentallySites, setDentallySites] = useState<GetSites_getSites_sites[]>([])
	const [validationError, setValidationError] = useState<{
		work_phone?: string | null
		home_phone?: string | null
		phone?: string | null
	} | null>(null)

	const [dentallyPaymentPlans, setDentallyPaymentPlans] = useState<
		GetPaymentPlans_getPaymentPlans_payment_plans[]
	>([])

	const [practitioners, setPractitioners] = useState<
		GetPractitioners_getPractitioners_practitioners[]
	>([])
	const [userId, setUserId] = useState('')
	const clinicsList = useQuery<ClinicsList>(clinicsListQuery)
	const { data: inquiryData } = useQuery<Inquiries>(inquiryListQuery)

	const [getSites] = useMutation<GetSites>(dentallySitesMutation, {
		onError: error => onError(error, toast),
	})

	const [getPaymentPlans] = useMutation<GetPaymentPlans>(dentallyPaymentPlansMutation, {
		onError: error => onError(error, toast),
	})

	const [getPractitioners] = useMutation<GetPractitioners, GetPractitionersVariables>(
		getPractitionersMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const { data: users, loading: usersLoading } = useQuery<ListUsers, ListUsersVariables>(
		listUsersQuery,
		{
			variables: { json: { role: { name: AssigneeTypes } } },
			onError: error => onError(error, toast),
		}
	)

	useEffect(() => {
		getSites().then(({ data }) => {
			if (data?.getSites) {
				setDentallySites(data.getSites.sites)
				formik.setFieldValue('site_id', data.getSites.sites[0].id)
			}
		})
	}, [])

	useEffect(() => {
		getPaymentPlans().then(({ data }) => {
			if (data?.getPaymentPlans) {
				setDentallyPaymentPlans(data.getPaymentPlans.payment_plans)
				formik.setFieldValue('payment_plan_id', data.getPaymentPlans.payment_plans[0].id)
			}
		})
	}, [])

	useEffect(() => {
		if (formik.values.site_id) {
			getPractitioners({
				variables: {
					params: {
						site_id: formik.values.site_id,
					},
				},
			}).then(({ data }) => {
				if (data?.getPractitioners) {
					setPractitioners(data.getPractitioners.practitioners)
				}
			})
		}
	}, [formik.values.site_id])

	const toast = useToast()
	const userViewModal = useDisclosure()

	const userList = users?.users?.filter(Boolean) as ListUsers_users[]

	const getEthnicityValue = (value: string) => {
		const item = DentallyEthnicityTypes.find(item => item.code === value)
		if (item) {
			return {
				value: item.code,
				label: item.description,
			}
		}
		return undefined
	}

	const getSiteValue = (value?: string | null) => {
		const item = dentallySites.find(site => site.id === value)
		if (item) {
			return {
				value: item.id,
				label: item.name,
			}
		}
		return undefined
	}

	const getPaymentPlanValue = (value?: number | null) => {
		const item = dentallyPaymentPlans.find(plan => plan.id === value)
		if (item) {
			return {
				value: item.id,
				label: item.name,
			}
		}
		return undefined
	}

	const getPractitionerValue = (value?: number | null) => {
		const practitioner = practitioners.find(practitioner => practitioner.id === value)
		if (practitioner && practitioner.user) {
			return {
				value: practitioner.id,
				label: practitioner.user.first_name + ' ' + practitioner.user.last_name,
			}
		}
		return null
	}

	const findPreferredClinicBySiteValue = (site: { label: string; value: string } | null) => {
		if (site) {
			const searchableClinic = clinicsList.data?.clinics?.find(
				clinic => site.value === clinic?.dentally_site_id
			)
			if (searchableClinic) {
				return searchableClinic.id
			}
			return null
		}
		return null
	}

	const onView = (id: string) => {
		setUserId(id)
		userViewModal.onOpen()
	}

	const capitalize = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	const phoneUtil = PhoneNumberUtil.getInstance()

	const isPhoneValid = (phone: string) => {
		try {
			return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
		} catch (error) {
			return false
		}
	}

	const hasValidationError = errorObject => {
		if (!errorObject) return false // If the object itself is null or undefined, return false
		// Check if at least one property has a non-null and non-undefined value
		return Object.values(errorObject).some(value => value !== null && value !== undefined)
	}

	if (!dentallySites.length) {
		return (
			<Flex justify='center' gap='1em' p='1em'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<Box sx={{ padding: '40px 30px' }}>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl isRequired w='11em' isInvalid={Boolean(formik?.errors?.title)}>
							<FormLabel>Title</FormLabel>
							<SelectDropdown
								variant='small'
								name='title'
								options={DentallyTitleEnumArray}
								placeholder='Select title'
								defaultValue={DentallyTitleEnumArray.find(opt => opt.value === formik.values.title)}
								onChange={selected => {
									const newOption = selected as SingleValue<{ value: string }>
									formik.setFieldValue('title', newOption?.value)
								}}
							/>
							<FormErrorMessage>Title is required</FormErrorMessage>
						</FormControl>
						<FormControl
							isRequired
							w='11em'
							isInvalid={formik.isSubmitting && Boolean(formik?.errors?.first_name)}
						>
							<FormLabel>First Name</FormLabel>
							<Input
								name='first_name'
								required={true}
								value={formik.values.first_name}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
							<FormErrorMessage>First name is required</FormErrorMessage>
						</FormControl>
						<FormControl isRequired w='11em' isInvalid={Boolean(formik?.errors?.last_name)}>
							<FormLabel>Last Name</FormLabel>
							<Input
								name='last_name'
								required={true}
								value={formik.values.last_name}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
							<FormErrorMessage>Last Name is required</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl
							isRequired
							w='18em'
							isInvalid={Boolean(formik?.errors?.date_of_birth)}
							sx={{
								'.react-datepicker-wrapper input': {
									width: '100%',
									background: '#fff',
									border: '1px solid rgb(226, 232, 240)',
									color: '#000',
									fontSize: '16px',
								},
							}}
						>
							<FormLabel>Date of birth</FormLabel>
							<DatePickerInput
								name='date_of_birth'
								yearDropdownItemNumber={100}
								showYearDropdown
								dateFormat='dd/MM/yyyy'
								scrollableYearDropdown
								minDate={moment().subtract(100, 'years').toDate()}
								maxDate={moment().toDate()}
								defaultValue={formik.values.date_of_birth}
								required={true}
								onChange={value => formik.setFieldValue('date_of_birth', value)}
							/>
						</FormControl>
						<FormControl isRequired w='18em'>
							<FormLabel>Gender</FormLabel>
							<RadioGroup
								onChange={nextValue => {
									formik.setFieldValue('gender', nextValue === 'true')
								}}
								value={String(formik.values.gender)}
							>
								<Stack direction='row'>
									<Radio
										value={String(DentallyGenderEnum.male)}
										_checked={{
											background: '#fff',
											borderColor: '#000',
											borderWidth: '6px',
											color: '#fff',
										}}
										borderWidth='2px'
										size='lg'
										marginRight='13px'
									>
										{capitalize(Object.keys(DentallyGenderEnum)[0])}
									</Radio>
									<Radio
										value={String(DentallyGenderEnum.female)}
										_checked={{
											background: '#fff',
											borderColor: '#000',
											borderWidth: '6px',
											color: '#fff',
										}}
										borderWidth='2px'
										size='lg'
									>
										{capitalize(Object.keys(DentallyGenderEnum)[1])}
									</Radio>
								</Stack>
							</RadioGroup>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl isRequired w='18em' isInvalid={Boolean(formik?.errors?.ethnicity)}>
							<FormLabel>Ethnicity</FormLabel>
							<SelectDropdown
								variant='small'
								name='ethnicity'
								value={getEthnicityValue(formik.values.ethnicity)}
								options={dataToSelectOptions(DentallyEthnicityTypes, 'code', 'description')}
								placeholder='Select ethnicity'
								onChange={selected => {
									const newOption = selected as SingleValue<{ value: string }>
									formik.setFieldValue('ethnicity', newOption?.value)
								}}
							/>
							<FormErrorMessage>Ethnicity is required</FormErrorMessage>
						</FormControl>
						<FormControl isRequired w='18em' isInvalid={Boolean(formik?.errors?.address_line_1)}>
							<FormLabel>Address</FormLabel>
							<Input
								name='address_line_1'
								required={true}
								value={formik.values.address_line_1}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
							<FormErrorMessage>Address is required</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl isRequired w='18em' isInvalid={Boolean(formik?.errors?.postcode)}>
							<FormLabel>Postcode</FormLabel>
							<Input
								name='postcode'
								required={true}
								value={formik.values.postcode}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
							<FormErrorMessage>Postcode is required</FormErrorMessage>
						</FormControl>
						<FormControl w='18em' isRequired isInvalid={Boolean(formik?.errors?.payment_plan_id)}>
							<FormLabel>Payment Plan</FormLabel>
							<SelectDropdown
								required={true}
								name='payment_plan_id'
								variant='small'
								options={dentallyPaymentPlans.map(plan => ({ label: plan.name, value: plan.id }))}
								placeholder='Select payment plan'
								value={getPaymentPlanValue(formik.values.payment_plan_id)}
								onChange={selected => {
									const newOption = selected as SingleValue<{ value: number }>
									formik.setFieldValue('payment_plan_id', newOption?.value)
								}}
							/>
							<FormErrorMessage>Payment plan is required</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl w='18em' isRequired isInvalid={Boolean(formik?.errors?.site_id)}>
							<FormLabel>Site</FormLabel>
							<SelectDropdown
								name='site_id'
								variant='small'
								options={dentallySites.map(site => ({ label: site.name, value: site.id }))}
								placeholder='Select site'
								value={getSiteValue(formik.values.site_id)}
								onChange={selected => {
									const clinic = findPreferredClinicBySiteValue(
										selected as SingleValue<{ value: string; label: string }>
									)

									const newOption = selected as SingleValue<{ value: string }>
									formik.setFieldValue('site_id', newOption?.value)
									formik.setFieldValue('dentist_id', null)
									clinic && formik.setFieldValue('clinic', clinic)
								}}
							/>
						</FormControl>
						<FormControl w='18em' isRequired isInvalid={Boolean(formik?.errors?.dentist_id)}>
							<FormLabel>Dentist</FormLabel>
							<SelectDropdown
								variant='small'
								required={true}
								name='dentist_id'
								isClearable={true}
								options={practitioners.map(practitioner => ({
									value: practitioner.id,
									label: practitioner.user?.first_name + ' ' + practitioner.user?.last_name,
								}))}
								value={getPractitionerValue(formik.values.dentist_id)}
								placeholder='Select preferred Dentist'
								onChange={selected => {
									const newOption = selected as SingleValue<{ value: number }>
									formik.setFieldValue('dentist_id', newOption?.value)
								}}
							/>
							<FormErrorMessage>Dentist is required</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl isRequired w='18em' isInvalid={Boolean(formik?.errors?.email)}>
							<FormLabel>Email Address</FormLabel>
							<Input
								type='email'
								name='email'
								required={true}
								value={formik.values.email}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
							<FormErrorMessage>Email Address is required</FormErrorMessage>
						</FormControl>
						<FormControl w='18em' isRequired isInvalid={Boolean(formik?.errors?.inquiries)}>
							<FormLabel>Inquiry Type</FormLabel>
							<SelectDropdown
								variant='small'
								required={true}
								isMulti
								name='inquiries'
								isClearable={true}
								options={inquiryData?.inquiries as Inquiries_inquiries[]}
								getOptionLabel={option => option.name as string}
								getOptionValue={option => option.id}
								placeholder='Select inquiries'
								onChange={selected => {
									const values = selected as MultiValue<Inquiries_inquiries>
									formik.setFieldValue('inquiries', values.length ? values?.map(val => val.id) : [])
								}}
							/>
							<FormErrorMessage>At least one inquiry is required</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl w='18em' isRequired isInvalid={Boolean(formik?.errors?.assignees)}>
							<FormLabel>Assignees</FormLabel>
							<SelectDropdown
								variant='small'
								isMulti
								required={true}
								options={userList}
								isDisabled={usersLoading}
								placeholder='Choose Assignees'
								getOptionLabel={opt => opt.username}
								getOptionValue={opt => opt.id}
								getOptionTag={opt => (
									<Tag
										colorScheme='green'
										size='sm'
										textTransform={
											['tco', 'cst'].includes(opt.role?.type || '') ? 'uppercase' : 'capitalize'
										}
									>
										{opt.role?.type}
									</Tag>
								)}
								components={{
									Option: PeopleOptionFactory<ListUsers_users>({ onView }),
								}}
								onChange={value => {
									const selectedValues = value as MultiValue<ListUsers_users>
									const ids = selectedValues.map(item => item.id)
									formik.setFieldValue('assignees', ids)
								}}
							/>
							<FormErrorMessage>Assignees is required</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl w='18em' isRequired isInvalid={Boolean(formik?.errors?.marketing)}>
							<FormLabel>Marketing Consent</FormLabel>
							<SelectDropdown
								variant='small'
								name='marketing'
								required={true}
								options={MarketingConsentArray}
								placeholder='Select marketing consent'
								defaultValue={MarketingConsentArray.find(
									opt => opt.value === formik.values.marketing
								)}
								onChange={selected => {
									const newOption = selected as SingleValue<{ value: number }>
									formik.setFieldValue('marketing', newOption?.value)
								}}
							/>
							<FormErrorMessage>Marketing consent is required</FormErrorMessage>
						</FormControl>
						<FormControl w='18em'>
							<FormLabel>Medical Alert</FormLabel>
							<Input
								name='medical_alert_text'
								value={formik.values.medical_alert_text || undefined}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl
							w='18em'
							isInvalid={Boolean(formik?.errors?.home_phone)}
							sx={{
								'.react-international-phone-input': {
									width: '100%',
									height: '40px !important',
								},
								'.react-international-phone-country-selector-button': {
									height: '40px',
								},
							}}
						>
							<FormLabel>Home Phone</FormLabel>
							<PhoneInput
								defaultCountry='gb'
								name='home_phone'
								required={true}
								value={formik.values.home_phone || undefined}
								onChange={phone => {
									setValidationError({ home_phone: null })
									formik.setFieldValue('home_phone', phone)
								}}
								onBlur={() => {
									if (formik.values.home_phone) {
										if (!isPhoneValid(formik.values.home_phone)) {
											setValidationError({ home_phone: 'Invalid phone format' })
										}
									}
								}}
								onFocus={() => {
									setValidationError({ home_phone: null })
								}}
								placeholder='Home phone'
							/>
							{validationError?.home_phone ? (
								<Text fontSize='sm' color='red.400' mt='0.5em'>
									{validationError.home_phone}
								</Text>
							) : null}
						</FormControl>
						<FormControl
							w='18em'
							sx={{
								'.react-international-phone-input': {
									width: '100%',
									height: '40px !important',
								},
								'.react-international-phone-country-selector-button': {
									height: '40px',
								},
							}}
						>
							<FormLabel>Work Phone</FormLabel>
							<PhoneInput
								defaultCountry='gb'
								name='work_phone'
								required={true}
								value={formik.values.work_phone || undefined}
								onChange={phone => {
									setValidationError({ work_phone: null })
									formik.setFieldValue('work_phone', phone)
								}}
								onBlur={() => {
									if (formik.values.work_phone) {
										if (!isPhoneValid(formik.values.work_phone)) {
											setValidationError({ work_phone: 'Invalid phone format' })
										}
									}
								}}
								onFocus={() => {
									setValidationError({ work_phone: null })
								}}
								placeholder='Work phone'
							/>
							{validationError?.work_phone ? (
								<Text fontSize='sm' color='red.400' mt='0.5em'>
									{validationError.work_phone}
								</Text>
							) : null}
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl
							w='18em'
							isRequired
							isInvalid={Boolean(formik?.errors?.phone)}
							sx={{
								'.react-international-phone-input': {
									width: '100%',
									height: '40px !important',
								},
								'.react-international-phone-country-selector-button': {
									height: '40px',
								},
							}}
						>
							<FormLabel>Mobile Phone</FormLabel>
							<PhoneInput
								defaultCountry='gb'
								name='phone'
								required={true}
								value={formik.values.phone || undefined}
								onChange={phone => {
									setValidationError({ phone: null })
									formik.setFieldValue('phone', phone)
								}}
								onBlur={() => {
									if (formik.values.phone) {
										if (!isPhoneValid(formik.values.phone)) {
											setValidationError({ phone: 'Invalid phone format' })
										}
									}
								}}
								onFocus={() => {
									setValidationError({ phone: null })
								}}
								placeholder='Mobile phone'
							/>
							{validationError?.phone ? (
								<Text fontSize='sm' color='red.400' mt='0.5em'>
									{validationError.phone}
								</Text>
							) : null}
						</FormControl>
						<FormControl w='18em'>
							<FormLabel>Preferred Phone Number</FormLabel>
							<SelectDropdown
								variant='small'
								required={true}
								name='preferred_phone_number'
								options={DentallyPreferredPhoneArray}
								placeholder='Select preferred phone number'
								defaultValue={DentallyPreferredPhoneArray.find(
									opt => opt.value === formik.values.preferred_phone_number
								)}
								onChange={selected => {
									const newOption = selected as SingleValue<{ value: number }>
									formik.setFieldValue('preferred_phone_number', newOption?.value)
								}}
							/>
						</FormControl>
					</Flex>
					<Flex align='center' justify='space-between' gap='1em' flexWrap='wrap' mb={3}>
						<FormControl isRequired w='18em' isInvalid={Boolean(formik?.errors?.source)}>
							<FormLabel>Source</FormLabel>
							<Input
								type='text'
								name='source'
								required={true}
								value={formik.values.source}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
							<FormErrorMessage>Source is required</FormErrorMessage>
						</FormControl>
						<FormControl w='18em'>
							<FormLabel>Medium</FormLabel>
							<Input
								type='text'
								name='medium'
								value={formik.values.medium || undefined}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
						</FormControl>
						<FormControl w='18em'>
							<FormLabel>Campaign</FormLabel>
							<Input
								type='text'
								name='campaign'
								value={formik.values.campaign || undefined}
								onChange={formik.handleChange}
								placeholder='Type here'
							/>
						</FormControl>
					</Flex>
					<Flex
						align='flex-start'
						direction='column'
						justify='space-between'
						gap='1em'
						flexWrap='wrap'
						mb={3}
					>
						<Text>Do you wants to receive details from the practice</Text>
						<FormControl isRequired w='18em' isInvalid={Boolean(formik?.errors?.date_of_birth)}>
							<RadioGroup
								name='use_email'
								onChange={nextValue => {
									formik.setFieldValue('use_email', nextValue === 'true')
								}}
								value={String(formik.values.use_email)}
							>
								<Stack direction='row'>
									<Radio
										value={String(true)}
										_checked={{
											background: '#fff',
											borderColor: '#000',
											borderWidth: '6px',
											color: '#fff',
										}}
										borderWidth='2px'
										size='lg'
										marginRight='13px'
									>
										Email
									</Radio>
									<Radio
										value={String(false)}
										_checked={{
											background: '#fff',
											borderColor: '#000',
											borderWidth: '6px',
											color: '#fff',
										}}
										borderWidth='2px'
										size='lg'
										marginRight='13px'
									>
										SMS
									</Radio>
								</Stack>
							</RadioGroup>
						</FormControl>
					</Flex>
				</Box>
				<Box
					sx={{
						background: '#FFF8F3',
						padding: '20px 40px',
						color: '#fff',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<Button
						type='submit'
						isLoading={formik.isSubmitting}
						sx={{
							background: '#2D3748',
							color: '#fff',
							fontSize: '17px',
							height: '40px',
							marginRight: '15px',
							width: '160px',
							textTransform: 'uppercase',
							borderRadius: '6px',
							_hover: {
								background: '#47546b',
							},
						}}
						isDisabled={formik.isSubmitting || hasValidationError(validationError)}
					>
						Submit
					</Button>
					<Button
						type='button'
						sx={{
							background: 'transparent',
							color: '#FF3A53',
							border: '2px solid #FF3A53',
							fontSize: '17px',
							height: '40px',
							width: '160px',
							textTransform: 'uppercase',
							borderRadius: '6px',
							_hover: {
								background: 'rgb(241 46 71 / 13%)',
							},
						}}
						onClick={goToPrevious}
					>
						Back
					</Button>
				</Box>
			</form>
			{userViewModal.isOpen && (
				<UserView userId={userId} isOpen={userViewModal.isOpen} onClose={userViewModal.onClose} />
			)}
		</Box>
	)
}

const CreatePatientForm: React.FC<CreatePatientFormProps> = ({
	goToPrevious,
	defaultFormValues,
}) => {
	const toast = useToast()

	const [createTrackerDentallyPatient] = useMutation<
		CreateTrackerDentallyPatient,
		CreateTrackerDentallyPatientVariables
	>(createTrackerDentallyPatientMutation, {
		onError: error => onError(error, toast),
	})

	const dentallyPatientValidationSchema = Yup.object().shape({
		title: Yup.string().required('Title is required'),
		first_name: Yup.string().required('First name is required'),
		last_name: Yup.string().required('Last name is required'),
		date_of_birth: Yup.string().required('Date of birth is required'),
		gender: Yup.string().required('Gender is required'),
		ethnicity: Yup.number().required('Ethnicity is required'),
		address_line_1: Yup.string().required('Address is required'),
		postcode: Yup.string().required('Postcode is required'),
		payment_plan_id: Yup.number().required('Payment plan is required'),
		site_id: Yup.string(),
		dentist_id: Yup.number().required('Dentist is required'),
		email: Yup.string().email('Invalid email address').required('Email is required'),
		marketing: Yup.number(),
		medical_alert_text: Yup.string(),
		preferred_phone_number: Yup.number(),
		work_phone: Yup.string(),
		home_phone: Yup.string(),
		phone: Yup.string().required('Phone number is required'),
		clinic: Yup.number().required('Clinic is required'),
		inquiries: Yup.array().required('Inquiries is required'),
		assignees: Yup.array().required('Assignees is required'),
		source: Yup.string().required('Source is required'),
		medium: Yup.string(),
		campaign: Yup.string(),
		use_email: Yup.boolean().required('Please select a contact method'),
		use_sms: Yup.boolean(),
	})

	const formik = useFormik({
		initialValues: {
			title: DentallyTitleEnum.Mr,
			first_name: '',
			last_name: '',
			email: defaultFormValues?.email || '',
			phone: '',
			clinic: 1,
			date_of_birth: '',
			gender: DentallyGenderEnum.male,
			inquiries: [],
			assignees: [],
			source: '',
			medium: '',
			campaign: '',
			ethnicity: DentallyEthnicityTypes[0].code,
			address_line_1: '',
			postcode: '',
			payment_plan_id: 5599,
			dentist_id: undefined,
			site_id: undefined,
			home_phone: undefined,
			marketing: undefined,
			medical_alert_text: undefined,
			preferred_phone_number: null,
			work_phone: undefined,
			use_email: true,
			use_sms: false,
		},
		validateOnChange: false,
		validationSchema: dentallyPatientValidationSchema,
		onSubmit: values => {
			submitHandler(values)
		},
	})

	const submitHandler = async (formData: createTrackerDentallyPatientFields) => {
		const model = {
			...formData,
			use_sms: !formData.use_email,
			dentist_id: formData.dentist_id ? Number(formData.dentist_id) : undefined,
			marketing: formData.marketing ? Number(formData.marketing) : null,
			payment_plan_id: Number(formData.payment_plan_id),
		}

		const { data, errors } = await createTrackerDentallyPatient({
			variables: {
				data: model,
			},
		})

		if (errors?.length) {
			toast({
				title: 'Something went wrong with patient creation',
				status: 'error',
				position: 'top-right',
			})
		}

		if (data?.createTrackerDentallyPatient) {
			return navigate(`/person/${data.createTrackerDentallyPatient.id}`)
		}
	}

	return (
		<CreatePatientFormInner
			goToPrevious={goToPrevious}
			formik={formik as unknown as FormikProps<createTrackerDentallyPatientFields>}
		/>
	)
}

export default CreatePatientForm
