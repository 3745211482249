import moment from 'moment'

export function userFriendlyDate(timestamp?: number, now = new Date()) {
	if (timestamp) {
		const isSeconds = timestamp.toString().length === 10
		const date = moment(isSeconds ? timestamp * 1000 : timestamp)

		if (!date.isValid()) {
			return 'Invalid Date'
		}

		const diffInHours = moment(now).diff(date, 'hours')

		if (diffInHours < 1) {
			const diffInMinutes = moment(now).diff(date, 'minutes')
			return `${diffInMinutes} min ago`
		}

		if (diffInHours < 24) {
			return `${diffInHours} hours ago`
		}

		if (diffInHours < 48) {
			return `Yesterday`
		}

		if (diffInHours < 24 * 7) {
			return `${date.format('dddd, HH:mm')}` // Full weekday name and time
		}

		if (diffInHours < 24 * 30 * 6) {
			return `${date.format('D MMM, HH:mm')}` // Day Month and time
		}

		return `${date.format('D MMM YYYY, LT')}` // Day Month Year and time
	} else {
		return ''
	}
}
