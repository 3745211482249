import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Box, Text, VStack, HStack, Divider } from '@chakra-ui/react'
import { format } from 'date-fns'

import React, { FC } from 'react'

import Button from '~/components/ui/Button'
import { FindLead_findLead_notes } from '~/views/__generated__/FindLead'

interface Props {
	note: FindLead_findLead_notes
	showDetails?: boolean
	onEdit?: () => void
	onDelete?: () => void
	onViewUser?: (userId: string) => void
}

const Note: FC<Props> = props => {
	const { note, showDetails, onEdit, onDelete, onViewUser } = props

	return (
		<VStack alignItems='start' sx={{ margin: '0 !important' }}>
			<Box bg='blue.200' rounded='md' px={5} pt={3} pb={2} w='100%' textAlign='left' width='auto'>
				<Text fontSize='sm' color='gray.700'>
					{note.text}
				</Text>
			</Box>
			<HStack spacing={5} fontSize='0.8em' color='gray.400' pr={2}>
				{showDetails && (
					<HStack spacing={2}>
						<Button
							title='Edit'
							aria-label='Edit'
							bg='blue.400'
							color='white'
							_hover={{ bg: 'blue.500' }}
							leftIcon={<EditIcon />}
							size='xs'
							onClick={onEdit}
						/>
						<Button
							title='Delete'
							aria-label='Delete'
							colorScheme='red'
							leftIcon={<DeleteIcon />}
							size='xs'
							onClick={onDelete}
						/>
					</HStack>
				)}
				<HStack spacing={1}>
					{note.owner?.username ? (
						<Text
							_hover={{
								color: showDetails ? 'blue.600' : '',
								cursor: showDetails ? 'pointer' : '',
							}}
							onClick={() => onViewUser && note.owner?.id && onViewUser(note.owner.id)}
						>
							{note.owner?.username},
						</Text>
					) : (
						<Text>System</Text>
					)}
					<Text>{format(new Date(note.updated_at), 'LLLL dd y, HH:mm')}</Text>
				</HStack>
			</HStack>
			<Divider sx={{ margin: '10px 0 0 0 !important' }} />
		</VStack>
	)
}

export default Note
