import { Textarea, Flex, Spinner, Button } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { IoSend } from 'react-icons/io5'

import React, { FC, useState, useEffect } from 'react'

const StyledButtonSend = styled(Button)`
	width: 36px;
	height: 36px;
	padding: 0;
	margin: 0;
	background: transparent;
	z-index: 222;
	&:hover {
		background: #e2e2e2;
	}
`
interface Props {
	onSend: (messageInput: string) => Promise<void>
	isLoading?: boolean
	isDisabled?: boolean
}

const ChatInput: FC<Props> = props => {
	const { onSend, isLoading, isDisabled } = props

	const [rows, setRows] = useState(1)
	const [messageInput, setMessageInput] = useState('')
	const [loading, setLoading] = useState(false)

	const handleOnSend = async () => {
		if (isLoading || loading || !messageInput) return

		setLoading(true)

		await onSend(messageInput)
		setMessageInput('')

		setLoading(false)
	}

	useEffect(() => {
		const rowlen = messageInput.split('\n')
		rowlen.length > 1 ? setRows(rowlen.length) : setRows(1)
	}, [messageInput])

	return (
		<Flex
			gap={6}
			position='relative'
			width='100%'
			sx={{
				justifyContent: 'space-between',
			}}
		>
			<Textarea
				rows={rows}
				size='sm'
				color='#64554B'
				width='95%'
				maxHeight='40px'
				disabled={loading || isLoading || isDisabled}
				_placeholder={{ color: '#64554B', opacity: '0.4' }}
				placeholder='Type something here...'
				rounded='md'
				padding='10px'
				outline='none'
				border='none'
				bg='white'
				resize='none'
				value={messageInput}
				onChange={evt => setMessageInput(evt.target.value)}
			/>
			<Flex>
				{loading ? (
					<Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
						<Spinner color='green.700' />
					</Flex>
				) : (
					<StyledButtonSend
						onClick={handleOnSend}
						cursor={loading || !messageInput ? 'not-allowed' : 'pointer'}
					>
						<IoSend
							fontSize='22px'
							color={loading || !messageInput ? 'rgb(204 204 204)' : 'rgb(84, 101, 112)'}
						/>
					</StyledButtonSend>
				)}
			</Flex>
		</Flex>
	)
}

export default ChatInput
