import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { UseFormReturn } from 'react-hook-form'

import React, { FC } from 'react'

import ContactAttemptsTab from '~/components/lead/components/ContactAttemptsTab'
import CorrespondenceTab from '~/components/lead/components/CorrespondenceTab'
import EmailsTab from '~/components/lead/components/EmailsTab'
import WhatsappTab from '~/components/lead/components/WhatsappTab'
// import WhatsappTab from '~/components/lead/components/WhatsappTab'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

import { StageInput as StageDataInput } from '../../../../__generated__/globalTypes'

interface Props {
	leadDetails?: FindLead_findLead
	mode: 'add' | 'edit'
	createStageForm: UseFormReturn<StageDataInput, unknown>
	onRefetch: () => Promise<void>
	active: boolean
}

const getTabsConfig = (
	props: Props
): { id: string; title: string; component: React.ReactNode }[] => {
	return [
		{
			id: 'contactAttemptsTab',
			title: 'Contact Attempts',
			component: <ContactAttemptsTab {...props} />,
		},
		{
			id: 'correspondenceTab',
			title: 'Correspondence',
			component: <CorrespondenceTab {...props} />,
		},
		{
			id: 'emailsTab',
			title: 'Emails',
			component: <EmailsTab {...props} />,
		},
		{
			id: 'whatappTab',
			title: 'Whatsapp',
			component: <WhatsappTab {...props} />,
		},
	]
}

const LeadMessages: FC<Props> = props => {
	const tabsConfig = getTabsConfig(props)
	return (
		<Flex>
			<Flex position='relative' direction='column' width='100%'>
				<Text color='#191C1A' fontSize='1.8em' fontWeight={700} casing='capitalize'>
					Messages
				</Text>
				<Tabs variant='enclosed' align='end' colorScheme='transparent' width='100%'>
					<TabList sx={{ alignItems: 'flex-end' }}>
						{tabsConfig.map(item => {
							return (
								<Tab
									_selected={{
										color: '#191C1A',
										fontWeight: 700,
										padding: '8px 20px',
										borderRadius: '8px 8px 0px 0px',
									}}
									color='#848484'
									background='#fff'
									border='1px solid #E0DDDB'
									borderColor='#E0DDDB'
									key={item.id}
									borderRadius='0'
									mr={0}
									fontSize='1em'
									padding='5px 11px'
								>
									{item.title}
								</Tab>
							)
						})}
					</TabList>
					<TabPanels>
						{tabsConfig.map(item => {
							return (
								<TabPanel
									key={item.id + 'content'}
									background='#fff'
									border='1px solid rgba(100, 85, 75, 0.2)'
									borderRadius='8px 0px 8px 8px'
								>
									{item.component}
								</TabPanel>
							)
						})}
					</TabPanels>
				</Tabs>
			</Flex>
		</Flex>
	)
}

export default LeadMessages
