import { useMutation, useQuery } from '@apollo/client'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
	Avatar,
	Box,
	Button,
	FormControl,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Spinner,
	Text,
	useToast,
} from '@chakra-ui/react'
import { MultiValue } from 'react-select'

import React, { useContext, useState } from 'react'

import SearchBar from '~/components/SearchBar'
import { PersonsSearch_personsSearch } from '~/components/__generated__/PersonsSearch'
import { deleteChatConversationMutation } from '~/components/chat/Chat'
import {
	DeleteChatConversation,
	DeleteChatConversationVariables,
} from '~/components/chat/__generated__/DeleteChatConversation'
import { FetchChatConversations_fetchChatConversations_conversations } from '~/components/chat/__generated__/FetchChatConversations'
import { userFriendlyDate } from '~/components/chat/helpers'
import { onError } from '~/components/helpers'
import { clinicsListQuery } from '~/components/kanban/Board'
import { ClinicsList } from '~/components/kanban/__generated__/ClinicsList'
import { AuthContext } from '~/components/page/context'
import SelectDropdown, { CustomValueContainer } from '~/components/ui/SelectDropdown'
import { createClinicOptions } from '~/helpers'
import { UpdatePerson, UpdatePersonVariables } from '~/views/__generated__/UpdatePerson'
import { updatePersonMutation } from '~/views/person'

interface ChatConversationsProps {
	conversations: FetchChatConversations_fetchChatConversations_conversations[] | undefined
	loading: boolean
	activeConversationId: string | null
	setActiveConversationId: (id: string | null) => void
	onOpenSearchPerson: (person: PersonsSearch_personsSearch) => void
	setChatLoading: (chatLoading: boolean) => void
}

const ChatConversations = ({
	conversations,
	loading,
	activeConversationId,
	setActiveConversationId,
	onOpenSearchPerson,
	setChatLoading,
}: ChatConversationsProps) => {
	const toast = useToast()
	const [filterValue, setFilterValue] = useState<{ clinics: number[] | null }>({ clinics: null })

	const user = useContext(AuthContext)

	const viewedMessages = JSON.parse(localStorage.getItem('viewedMessages') || '[]')

	const [updatePerson] = useMutation<UpdatePerson, UpdatePersonVariables>(updatePersonMutation, {
		onError: error => onError(error, toast),
	})

	const [deleteChatConversation] = useMutation<
		DeleteChatConversation,
		DeleteChatConversationVariables
	>(deleteChatConversationMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['FetchChatConversations', 'PersonDetails'],
	})

	const clinicsList = useQuery<ClinicsList>(clinicsListQuery)

	const clinicOptions = createClinicOptions(clinicsList?.data)

	const getFilteredConversations = () => {
		if (!conversations || !conversations.length) return []
		if (!filterValue.clinics) return conversations

		return conversations.filter(conversation => {
			// Check if the conversation has any uniqueClinics and filter based on the clinic IDs in filterValue.clinics
			return conversation.uniqueClinics?.some(
				clinic => clinic && filterValue.clinics?.includes(Number(clinic.id))
			)
		})
	}

	const handleDeleteConversation = async (
		conversation: FetchChatConversations_fetchChatConversations_conversations,
		onClose: () => void
	) => {
		setChatLoading(true)
		if (conversation.person?.id) {
			await updatePerson({
				variables: {
					personData: {
						where: { id: conversation.person.id },
						data: { twilio_conversation_sid: null },
					},
				},
			})
		}
		await deleteChatConversation({
			variables: { conversationSid: conversation.conversationSid },
		})
		onClose()
		setChatLoading(false)
	}

	const renderConversationItem = (
		conversation: FetchChatConversations_fetchChatConversations_conversations
	) => {
		const handleClickConversationItem = () => {
			setActiveConversationId(conversation.conversationSid)

			const viewedMessages = JSON.parse(localStorage.getItem('viewedMessages') || '[]')
			if (!viewedMessages.includes(conversation.lastMessage?.sid)) {
				viewedMessages.push(conversation.lastMessage?.sid)
				localStorage.setItem('viewedMessages', JSON.stringify(viewedMessages))
			}

			setActiveConversationId(conversation.conversationSid)
		}

		const isLastMessageViewed = viewedMessages.includes(conversation.lastMessage?.sid)
		const showNewMessageIndicator =
			conversation.lastMessage?.sid &&
			!conversation.lastMessage?.author.includes('Ruhdental') &&
			!isLastMessageViewed

		return (
			<Box
				onClick={handleClickConversationItem}
				sx={{
					width: '100%',
					height: '72px',
					position: 'relative',
					borderBottom: '1px solid rgb(233, 237, 239)',
					display: 'flex',
					padding: '0 12px',
					alignItems: 'center',
					backgroundColor:
						activeConversationId === conversation.conversationSid ? '#f0f2f5' : 'white',
					'&:hover': {
						backgroundColor: '#f5f6f6',
						cursor: 'pointer',
						'> .chat-menu-button': {
							visibility: 'visible',
							transition: '0.3s',
							right: '8px',
							opacity: 1,
						},
						'> .new-message-indicator': {
							right: '28px',
							transition: '0.3s',
						},
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '60px',
					}}
				>
					<Avatar
						w='49px'
						h='49px'
						name={
							conversation.person
								? `${conversation.person?.first_name} ${conversation.person?.last_name}`
								: 'N/A'
						}
						border='4px solid #EEAB7E'
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexBasis: 'auto',
						flexGrow: 1,
						flexDirection: 'column',
						width: 'calc(100% - 71px)',
					}}
				>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Text
							fontSize='1em'
							color='#111b21'
							fontWeight={showNewMessageIndicator ? 600 : 500}
							p={0}
						>
							{conversation.person
								? `${conversation.person?.first_name} ${conversation.person?.last_name}`
								: 'N/A'}
						</Text>
						<Text
							color={showNewMessageIndicator ? '#25d366' : '#6677'}
							fontSize='0.8em'
							fontWeight='400'
						>
							{userFriendlyDate(Number(conversation.lastMessage?.dateUpdated))}
						</Text>
					</Box>
					<Text
						fontSize='0.9em'
						color='#667781'
						width='95%'
						fontWeight={showNewMessageIndicator ? 500 : 400}
						sx={{
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
						}}
					>
						{conversation.lastMessage?.body || 'No messages'}
					</Text>
				</Box>
				{showNewMessageIndicator ? (
					<Box
						className='new-message-indicator'
						sx={{
							width: '16px',
							height: '16px',
							position: 'absolute',
							bottom: '17px',
							right: '10px',
							borderRadius: '50%',
							background: '#25d366',
						}}
					/>
				) : null}
				{user.role?.name === 'Admin' ? (
					<Menu>
						{({ onClose }) => (
							<>
								<MenuButton
									onClick={event => {
										event.stopPropagation()
									}}
									as={Button}
									className='chat-menu-button'
									sx={{
										position: 'absolute',
										visibility: 'hidden',
										opacity: 0,
										right: '0px',
										minWidth: 'auto',
										background: 'transparent',
										width: '24px',
										height: '24px',
										padding: 0,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										bottom: '15px',
										'&:hover': {
											background: '#e2e2e2',
										},
									}}
								>
									<ChevronDownIcon />
								</MenuButton>
								<MenuList>
									<MenuItem
										sx={{ color: '#667781' }}
										onClick={async event => {
											event.stopPropagation()
											await handleDeleteConversation(conversation, onClose)
										}}
									>
										Delete
									</MenuItem>
								</MenuList>
							</>
						)}
					</Menu>
				) : null}
			</Box>
		)
	}

	const renderConversations = () => {
		if (loading) {
			return (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						width: '100%',
						height: '100%',
					}}
				>
					<Spinner color='gray.500' />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Box>
			)
		}
		if (!conversations?.length) {
			return (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						width: '100%',
						height: '100%',
					}}
				>
					<Text color='gray.500' fontSize='sm'>
						No conversations
					</Text>
				</Box>
			)
		}
		return (
			<Box sx={{ overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%' }}>
				{getFilteredConversations()?.map(conversation => {
					return (
						<React.Fragment key={conversation.conversationSid}>
							{renderConversationItem(conversation)}
						</React.Fragment>
					)
				})}
			</Box>
		)
	}

	return (
		<Box
			sx={{
				width: '30%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				borderRight: '1px solid rgb(233, 237, 239)',
			}}
		>
			<Box sx={{ height: '60px' }}>
				<Text fontSize='1.2em' color='#111b21' fontWeight={600} py={3} px={6}>
					All conversations
				</Text>
			</Box>
			<Box
				sx={{
					minHeight: '50px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<SearchBar chatView onOpenSearchPerson={onOpenSearchPerson} />
			</Box>
			<Box sx={{ padding: '0 15px', margin: '10px 0' }}>
				<FormControl w='11.2em'>
					<SelectDropdown
						isMulti
						options={clinicOptions}
						placeholder='Clinic'
						getOptionLabel={option => option.label as string}
						getOptionValue={option => option.value as string}
						variant='small'
						isClearable
						components={{
							ValueContainer: CustomValueContainer({
								selectedLabel: ['clinics', 'clinic'],
							}),
						}}
						onChange={selected => {
							const values = selected as MultiValue<{ label: string; value: number }>

							if (!values.length) {
								setFilterValue({ clinics: null })
								return
							}

							setFilterValue({ clinics: values.map(val => val.value) })
						}}
					/>
				</FormControl>
			</Box>
			{renderConversations()}
		</Box>
	)
}

export default ChatConversations
