import { gql, useMutation, useQuery } from '@apollo/client'
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import moment from 'moment'
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

import React, { FC, useContext, useEffect, useRef } from 'react'

import ChatInput from '~/components/chat/ChatInput'
import ChatMessage from '~/components/chat/ChatMessage'
import {
	FetchChatMessages,
	FetchChatMessages_fetchChatMessages_messages,
	FetchChatMessagesVariables,
} from '~/components/lead/components/chat/__generated__/FetchChatMessages'
import {
	GetChatTemplates,
	GetChatTemplatesVariables,
} from '~/components/lead/components/chat/__generated__/GetChatTemplates'
import {
	SendChatMessage,
	SendChatMessageVariables,
} from '~/components/lead/components/chat/__generated__/SendChatMessage'
import {
	StartChat,
	StartChatVariables,
} from '~/components/lead/components/chat/__generated__/StartChat'
import { AuthContext } from '~/components/page/context'
import { socket } from '~/context/SocketProvider'
import { PersonDetails, PersonDetailsVariables } from '~/inputs/__generated__/PersonDetails'
import { personDetailQuery } from '~/inputs/person'
import { FindLead_findLead_person } from '~/views/__generated__/FindLead'

import { sendChatMessageInput } from '../../../../../__generated__/globalTypes'
import { joinValues, onError } from '../../../helpers'
import useToast from '../../../ui/Toast'

interface Props {
	person: FindLead_findLead_person
	unreadMessageId?: string
	hasUnreadMessage?: boolean
	leadId?: string
	onRefetch: () => Promise<void>
}

export const startChatMutation = gql`
	mutation StartChat($params: startChatInput!) {
		startChat(params: $params) {
			conversationSid
		}
	}
`

export const fetchChatMessagesMutation = gql`
	mutation FetchChatMessages($conversationSid: String!) {
		fetchChatMessages(conversationSid: $conversationSid) {
			messages {
				accountSid
				conversationSid
				sid
				index
				author
				body
				media {
					sid
					date_created
					links {
						content_direct_temporary
					}
					content_type
				}
				attributes
				participantSid
				dateCreated
				dateUpdated
				url
				contentSid
			}
		}
	}
`

export const markAllMessagesAsReadMutation = gql`
	mutation MarkAllMessagesAsRead($conversationSid: String!) {
		markAllMessagesAsRead(conversationSid: $conversationSid) {
			success
		}
	}
`

export const sendChatMessageMutation = gql`
	mutation SendChatMessage($params: sendChatMessageInput!) {
		sendChatMessage(params: $params) {
			accountSid
			conversationSid
			sid
			index
			author
			body
			media {
				sid
				date_created
				links {
					content_direct_temporary
				}
				content_type
			}
			attributes
			participantSid
			dateCreated
			dateUpdated
			url
			contentSid
		}
	}
`

export const getChatTemplatesQuery = gql`
	query GetChatTemplates($params: chatTemplatesInput!) {
		getChatTemplates(params: $params) {
			templates {
				sid
				friendlyName
				types
				variables
			}
		}
	}
`

/***
 *
 * Chat Container Component
 *
 ***/
const WhatsappContainer: FC<Props> = props => {
	const { person } = props
	const toast = useToast()
	const user = useContext(AuthContext)
	const [messages, setMessages] = React.useState<FetchChatMessages_fetchChatMessages_messages[]>([])
	const msgListRef = useRef<HTMLDivElement>(null)
	const messagesEndRef = useRef<HTMLDivElement>(null)
	const [chatLoading, setChatLoading] = React.useState(false)
	const [openedSid, setOpenedSid] = React.useState<string | null>(null)

	const { data: personDetails, loading: personDetailsLoading } = useQuery<
		PersonDetails,
		PersonDetailsVariables
	>(personDetailQuery, {
		variables: { personId: person.id },
	})

	const { data: chatTemplates, loading: chatTemplatesLoading } = useQuery<
		GetChatTemplates,
		GetChatTemplatesVariables
	>(getChatTemplatesQuery, {
		variables: {
			params: {
				limit: 100,
			},
		},
	})

	const conversationSid = personDetails?.person?.twilio_conversation_sid

	const [showTemplates, setShowTemplates] = React.useState<boolean>(false)

	useEffect(() => {
		socket.on('onConversationUpdated', event => {
			if (event.ConversationSid === personDetails?.person?.twilio_conversation_sid) {
				fetchChatMessages().then(res => {
					if (res?.data?.fetchChatMessages.messages) {
						setMessages(res.data.fetchChatMessages.messages)
					}
				})
			}
		})
	}, [personDetails?.person?.twilio_conversation_sid])

	useEffect(() => {
		if (personDetails?.person?.twilio_conversation_sid) {
			const fetcher = async () => {
				await fetchChatMessages().then(res => {
					if (res?.data?.fetchChatMessages.messages) {
						setMessages(res.data.fetchChatMessages.messages)
					}
				})
			}
			fetcher()
		}
	}, [personDetails?.person?.twilio_conversation_sid])

	const [startChat] = useMutation<StartChat, StartChatVariables>(startChatMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['PersonDetails'],
	})

	const [sendChatMessage, { loading: sendChatMessageLoading }] = useMutation<
		SendChatMessage,
		SendChatMessageVariables
	>(sendChatMessageMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['PersonDetails'],
	})

	const [fetchChatMessages, { loading: fetchChatMessagesLoading }] = useMutation<
		FetchChatMessages,
		FetchChatMessagesVariables
	>(fetchChatMessagesMutation, {
		variables: {
			conversationSid: personDetails?.person?.twilio_conversation_sid as string,
		},
		onError: error => onError(error, toast),
	})

	const getAllMessagesImages = () => {
		return messages.reduce((acc, message) => {
			if (message.media) {
				message.media.forEach(mediaItem => {
					if (
						mediaItem?.content_type?.includes('image') &&
						mediaItem.links?.content_direct_temporary
					) {
						acc.push({
							src: mediaItem.links?.content_direct_temporary,
							id: mediaItem?.sid as string,
						})
					}
				})
			}
			return acc
		}, [] as { src: string; id: string }[])
	}

	const handleInitConversation = async (
		contentSid: string,
		contentVariables?: { [key: string]: string }
	) => {
		await startChat({
			variables: {
				params: {
					friendlyName: 'New conversation with ' + person.first_name,
					personId: person.id,
					contentSid: contentSid,
					authorName: user.username + ' Ruhdental',
					contentVariables: contentVariables,
				},
			},
		})
		setChatLoading(false)
	}

	const handleSendMessage = async (
		message?: string,
		contentSid?: string,
		contentVariables?: { [key: string]: string }
	) => {
		const sendMessageParams: sendChatMessageInput = {
			conversationSid: personDetails?.person?.twilio_conversation_sid as string,
			authorName: user.username + ' Ruhdental',
		}
		if (contentSid) {
			sendMessageParams.contentSid = contentSid
		}
		if (contentVariables) {
			sendMessageParams.contentVariables = contentVariables
		}
		if (message) {
			sendMessageParams.message = message
		}
		if (showTemplates) {
			setShowTemplates(false)
			setChatLoading(true)
		}
		await sendChatMessage({
			variables: {
				params: sendMessageParams,
			},
		})
		await fetchChatMessages().then(res => {
			if (res?.data?.fetchChatMessages.messages) {
				setMessages(res.data.fetchChatMessages.messages)
			}
		})
		if (chatLoading) {
			setChatLoading(false)
		}
	}

	useEffect(() => {
		if (messages.length) {
			const lastMessage = messages[messages.length - 1]
			scrollChatContainer(lastMessage.sid)
		}
	}, [messages])

	const scrollChatContainer = (messageId?: string) => {
		if (messageId) {
			const messageDiv = document.getElementById(messageId)
			messageDiv?.scrollIntoView({ behavior: 'smooth' })
			return
		}

		const scrollHeight = msgListRef?.current?.scrollHeight ?? 0
		const height = msgListRef?.current?.clientHeight ?? 0
		const maxScrollTop = scrollHeight - height

		if (msgListRef?.current?.scrollTo) {
			msgListRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
		}
	}

	if (personDetailsLoading) {
		return (
			<Flex justify='center' gap='1em' p='1em'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)
	}

	const renderTemplatesPicker = () => {
		if (chatTemplatesLoading) {
			return (
				<Flex justify='center' gap='1em' p='1em' width='100%'>
					<Spinner />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Flex>
			)
		}

		const onSendMessage = async (sid: string) => {
			if (conversationSid) {
				await handleSendMessage('', sid)
			} else {
				setChatLoading(true)
				await handleInitConversation(sid)
			}
		}

		return (
			<Box
				mb={6}
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				{chatTemplates?.getChatTemplates.templates?.map(template => {
					return (
						<Box
							key={template.sid}
							onClick={() => onSendMessage(template.sid as string)}
							p={2}
							mx={2}
							my={1}
							background='#fff'
							borderRadius='8px'
							display='inline-flex'
							width='auto'
							_hover={{ cursor: 'pointer', background: '#f8f8f8' }}
						>
							<Text
								width='auto'
								display='inline-flex'
								color='gray.500'
								fontSize='13px'
								textAlign='left'
							>
								{template.types['twilio/text'].body}
							</Text>
						</Box>
					)
				})}
			</Box>
		)
	}

	const renderChatContent = () => {
		const messageImages = getAllMessagesImages()
		return (
			<Box
				w='100%'
				gap={2}
				display='flex'
				flexDirection='column'
				paddingBottom='40px'
				position='relative'
			>
				{conversationSid ? (
					<Button
						onClick={() => setShowTemplates(!showTemplates)}
						sx={{
							width: '130px',
							position: 'sticky',
							fontSize: '14px',
							fontWeight: 500,
							background: '#fff',
							marginTop: '5px',
							top: '5px',
							borderRadius: '35px',
							zIndex: 20,
							left: 'calc(50% - 65px)',
						}}
					>
						{showTemplates ? 'Hide' : 'Show'} templates
					</Button>
				) : null}
				{showTemplates || !conversationSid ? (
					<Box
						sx={{
							padding: '10px 20px',
						}}
					>
						{renderTemplatesPicker()}
					</Box>
				) : (
					messages.map(message => {
						return (
							<ChatMessage
								setOpenedSid={setOpenedSid}
								key={message.sid}
								messageData={{
									id: message.sid,
									author: message.author.includes('Ruhdental')
										? message.author
										: person?.first_name || 'N/A',
									body: message.body as string,
									createdAt: moment(Number(message.dateCreated)).toISOString(),
									media: message.media,
								}}
							/>
						)
					})
				)}
				<Lightbox
					open={Boolean(openedSid)}
					close={() => setOpenedSid(null)}
					slides={messageImages}
					index={messageImages.findIndex(image => image.id === openedSid)}
					plugins={[Thumbnails]}
				/>
				{/* This is the reference div for auto-scrolling */}
				<div ref={messagesEndRef} />
			</Box>
		)
	}

	const renderChatLoader = () => {
		if (!chatLoading) {
			return null
		}
		return (
			<Flex
				gap='1em'
				p='1em'
				sx={{
					position: 'absolute',
					zIndex: 20,
					background: 'rgba(255,255,255,0.7)',
					top: 0,
					alignItems: 'center',
					justifyContent: 'center',
					left: 0,
					width: '100%',
					height: '100%',
				}}
			>
				<Spinner color='gray.500' />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)
	}

	const isLoading = fetchChatMessagesLoading && !messages.length

	return (
		<Box
			w='full'
			border='1px solid rgba(100, 85, 75, 0.2)'
			borderRadius='8px'
			padding='10px 20px 20px 20px'
		>
			<Text fontWeight='bold' color='#64554B' textAlign='left'>
				Whatsapp chat with {joinValues(person?.first_name, person?.middle_name, person?.last_name)}
			</Text>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					position: 'relative',
					justifyContent: 'space-between',
					height: '28em',
				}}
			>
				{renderChatLoader()}
				<Box
					sx={{
						display: 'flex',
						flexGrow: 1,
						flexDirection: 'column',
						overflowY: 'auto',
						background: '#dedede',
						gap: 5,
						padding: '0 15px',
					}}
				>
					{isLoading ? (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
								width: '100%',
								height: '100%',
							}}
						>
							<Spinner color='gray.500' />
							<Text color='gray.500' fontSize='sm'>
								Loading
							</Text>
						</Box>
					) : (
						renderChatContent()
					)}
				</Box>
				<Box
					sx={{
						minHeight: '60px',
						backgroundColor: '#f0f2f5',
						alignItems: 'center',
						display: 'flex',
						width: '100%',
						padding: '0 12px',
					}}
				>
					<ChatInput
						isLoading={sendChatMessageLoading}
						onSend={handleSendMessage}
						isDisabled={!messages.length}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export default WhatsappContainer
