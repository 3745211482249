import { Flex } from '@chakra-ui/react'

import React, { FC } from 'react'

import WhatsappContainer from '~/components/lead/components/chat/WhatsappContainer'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

interface CorrespondenceTabProps {
	leadDetails?: FindLead_findLead
	mode: 'add' | 'edit'
	onRefetch: () => Promise<void>
}

const WhatsappTab: FC<CorrespondenceTabProps> = props => {
	const { leadDetails, onRefetch } = props

	if (!leadDetails || !leadDetails?.person) return null

	return (
		<Flex direction='column'>
			<WhatsappContainer
				leadId={leadDetails.id || ''}
				person={leadDetails?.person}
				hasUnreadMessage={leadDetails.has_unread_message || false}
				unreadMessageId={leadDetails.unread_message_id || ''}
				onRefetch={onRefetch}
			/>
		</Flex>
	)
}

export default WhatsappTab
