import { Flex } from '@chakra-ui/react'

import React, { FC } from 'react'

import ChatContainer from '~/components/lead/components/chat/ChatContainer'
import { FindLead_findLead } from '~/views/__generated__/FindLead'

interface CorrespondenceTabProps {
	leadDetails?: FindLead_findLead
	mode: 'add' | 'edit'
	onRefetch: () => Promise<void>
}

const CorrespondenceTab: FC<CorrespondenceTabProps> = props => {
	const { leadDetails, mode, onRefetch } = props
	const inEditMode = mode === 'edit'

	return (
		<Flex direction='column'>
			{leadDetails?.person?.id && inEditMode && (
				<ChatContainer
					leadId={leadDetails.id || ''}
					person={leadDetails?.person}
					hasUnreadMessage={leadDetails.has_unread_message || false}
					unreadMessageId={leadDetails.unread_message_id || ''}
					onRefetch={onRefetch}
				/>
			)}
		</Flex>
	)
}

export default CorrespondenceTab
