import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Flex,
	Heading,
	Spinner,
	Switch,
	Text,
} from '@chakra-ui/react'
import { SingleValue } from 'react-select'

import React, { FC, useEffect, useState } from 'react'

import { onError } from '~/components/helpers'
// import BPView from '~/components/settings/BPView'
import ClinicTargets from '~/components/settings/ClinicTargets'
import { SyncDentally_syncDentally } from '~/components/settings/__generated__/SyncDentally'
import {
	getAppointmentReasonsSettings,
	getAppointmentReasonsSettings_getAppointmentReasonsSettings,
} from '~/components/settings/__generated__/getAppointmentReasonsSettings'
import {
	updateAppointmentReasonsSettings,
	updateAppointmentReasonsSettingsVariables,
} from '~/components/settings/__generated__/updateAppointmentReasonsSettings'
import Button from '~/components/ui/Button'
import SelectDropdown from '~/components/ui/SelectDropdown'
import useToast from '~/components/ui/Toast'

import { newAppointmentReasons } from '../../../__generated__/globalTypes'

export const AppointmentReasonsSettingsQuery = gql`
	query getAppointmentReasonsSettings {
		getAppointmentReasonsSettings {
			id
			name
			enabled
			consult_booked
		}
	}
`

export const updateAppointmentReasonsSettingsMutation = gql`
	mutation updateAppointmentReasonsSettings($data: [newAppointmentReasons!]!) {
		updateAppointmentReasonsSettings(data: $data) {
			id
			name
			enabled
			consult_booked
		}
	}
`

export const syncDentallyMutation = gql`
	mutation SyncDentally {
		syncDentally {
			success
		}
	}
`

const querySettings = gql`
	query Settings {
		settings {
			id
			send_wizard_emails
		}
	}
`

const updateSettingsMutation = gql`
	mutation UpdateSetting($settingData: updateSettingInput!) {
		updateSetting(input: $settingData) {
			setting {
				id
			}
		}
	}
`

const ReasonOptions = [
	{ label: 'Consult Booked', value: true },
	{ label: 'Appointment Booked', value: false },
]

const Settings: FC = () => {
	const { data, loading } = useQuery<getAppointmentReasonsSettings>(AppointmentReasonsSettingsQuery)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [syncIsLoading, setSyncIsLoading] = useState<boolean>(false)
	const [formData, setFormData] = useState<newAppointmentReasons[]>([])
	const [sendWizardEmailsValue, setSendWizardEmailsValue] = useState<boolean>(false)
	const [switchLoading, setSwitchLoading] = useState(false)
	const toast = useToast()

	const { data: settingsData, loading: settingsLoading } = useQuery(querySettings)

	const [updateSettings] = useMutation(updateSettingsMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['Settings'],
	})

	// UseEffect to set initial value for sendWizardEmailsValue when settingsData is available
	useEffect(() => {
		if (!settingsLoading && settingsData?.settings?.[0]?.send_wizard_emails !== undefined) {
			setSendWizardEmailsValue(settingsData.settings[0].send_wizard_emails)
		}
	}, [settingsLoading, settingsData])

	const [updateReasons] = useMutation<
		updateAppointmentReasonsSettings,
		updateAppointmentReasonsSettingsVariables
	>(updateAppointmentReasonsSettingsMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['getAppointmentReasonsSettings'],
		onCompleted: () => setFormData([]),
	})

	const [syncDentally] = useMutation<SyncDentally_syncDentally>(syncDentallyMutation, {
		onError: error => onError(error, toast),
		onCompleted: () => {
			toast({
				title: 'Sync completed',
				status: 'success',
				position: 'top-right',
			})
		},
	})

	const checkChangedValue = (reason_id: string) => {
		return formData.find((item: newAppointmentReasons) => item.reason_id === reason_id)
	}

	const getSelectValue = (
		stateItem: newAppointmentReasons | undefined,
		appointmentReason: getAppointmentReasonsSettings_getAppointmentReasonsSettings
	) => {
		const currentValue = stateItem ? stateItem.consult_booked : appointmentReason.consult_booked
		return ReasonOptions.find(reason => reason.value === currentValue)
	}

	const handleSave = async () => {
		setIsLoading(true)
		await updateReasons({ variables: { data: formData } })
		setIsLoading(false)
	}

	const handleSync = async () => {
		setSyncIsLoading(true)
		await syncDentally()
		setSyncIsLoading(false)
	}

	const handleChange = (
		stateItem: newAppointmentReasons | undefined,
		appointmentReason: getAppointmentReasonsSettings_getAppointmentReasonsSettings,
		newField: { [key: string]: boolean }
	) => {
		if (stateItem) {
			// Update existing item in formData
			const updatedFormData = formData.map(item => {
				if (item.reason_id === appointmentReason.id) {
					return { ...item, ...newField }
				}
				return item
			})
			setFormData(updatedFormData)
		} else {
			// Add new item to formData
			const model: newAppointmentReasons = {
				reason_id: appointmentReason.id,
				name: appointmentReason.name as string,
				enabled: appointmentReason.enabled,
				consult_booked: appointmentReason.consult_booked,
				...newField,
			}
			setFormData([...formData, { ...model }])
		}
	}

	const handleSwitchChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = evt.target.checked
		setSwitchLoading(true) // Start loading for Switch

		try {
			await updateSettings({
				variables: {
					settingData: {
						where: { id: settingsData?.settings[0].id },
						data: { send_wizard_emails: newValue },
					},
				},
			})
			setSendWizardEmailsValue(newValue) // Update local state directly after mutation
		} catch (error) {
			console.error('Error updating settings:', error)
		} finally {
			setSwitchLoading(false) // End loading for Switch
		}
	}

	const renderAppointmentReasonsForm = () => {
		if (loading) {
			return (
				<Flex justify='center' gap='1em' p='1em' minHeight='500px'>
					<Spinner />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Flex>
			)
		}
		return (
			<CardBody paddingTop={0}>
				{data?.getAppointmentReasonsSettings.map(appointmentReason => {
					const stateItem = checkChangedValue(appointmentReason.id)
					return (
						<Flex
							bg='white'
							px={4}
							py={2}
							rounded='md'
							shadow='sm'
							align='center'
							gap={2}
							key={appointmentReason.id}
						>
							<Flex width='300px'>
								<Switch
									onChange={evt => {
										handleChange(stateItem, appointmentReason, { enabled: evt.target.checked })
									}}
									colorScheme='twitter'
									defaultChecked={stateItem ? stateItem.enabled : appointmentReason.enabled}
								/>
								<Text fontSize='0.8em' ml={2}>
									{appointmentReason.name}
								</Text>
							</Flex>
							<Flex width='200px'>
								<SelectDropdown
									variant='small'
									options={ReasonOptions}
									value={getSelectValue(stateItem, appointmentReason)}
									onChange={selected => {
										const option = selected as SingleValue<{ label: string; value: boolean }>
										handleChange(stateItem, appointmentReason, { consult_booked: option!.value })
									}}
								/>
							</Flex>
						</Flex>
					)
				})}
			</CardBody>
		)
	}

	return (
		<>
			<Flex bg='#F2F2F2' height='3em' alignItems='center' px={12} />
			<Flex height='calc(100vh - 5em - 3em)'>
				<Flex
					bg='white'
					minWidth='100%'
					padding='25px'
					direction='column'
					overflowY='scroll'
					pb={10}
				>
					<Text color='#191C1A' fontSize='xl'>
						Settings
					</Text>
					<Card mt={1}>
						<CardHeader>
							<Heading size='md'>Dentally data</Heading>
						</CardHeader>
						<Flex gap='1em' padding='0px 20px 10px 20px'>
							<Button
								colorScheme='green'
								isLoading={syncIsLoading}
								onClick={handleSync}
								width='200px'
								disabled={syncIsLoading}
							>
								Sync dentally data
							</Button>
						</Flex>
					</Card>

					<Card mt={1} position='relative'>
						<CardHeader>
							<Heading size='md'>Emails</Heading>
						</CardHeader>
						<Flex gap='1em' padding='0px 20px 20px 20px'>
							<Flex>
								<Switch
									isChecked={sendWizardEmailsValue}
									onChange={handleSwitchChange}
									colorScheme='twitter'
									isDisabled={switchLoading} // Optional: disable switch while loading
								/>
								<Text fontSize='0.8em' ml={2}>
									Send Wizard Email
								</Text>
							</Flex>
						</Flex>
					</Card>

					<Card mt={2}>
						<CardHeader>
							<Heading size='md'>Appointment reasons</Heading>
						</CardHeader>
						{renderAppointmentReasonsForm()}
						<CardFooter>
							<Button
								colorScheme='green'
								isDisabled={isLoading || !formData.length || loading}
								isLoading={isLoading}
								onClick={handleSave}
							>
								Save
							</Button>
						</CardFooter>
					</Card>
					<ClinicTargets />
					{/*<BPView />*/}
				</Flex>
			</Flex>
		</>
	)
}

export default Settings
