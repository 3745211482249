import {
	ApolloQueryResult,
	gql,
	useMutation,
	OperationVariables,
	useLazyQuery,
} from '@apollo/client'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	Skeleton,
	Stack,
	Flex,
	Text,
} from '@chakra-ui/react'
import { PersonInput } from '__generated__/globalTypes'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { Controller, useForm } from 'react-hook-form'
import { PhoneInput } from 'react-international-phone'
import 'react-phone-input-2/lib/style.css'

import React, { FC, useEffect, useState } from 'react'

import { deleteChatConversationMutation } from '~/components/chat/Chat'
import {
	DeleteChatConversation,
	DeleteChatConversationVariables,
} from '~/components/chat/__generated__/DeleteChatConversation'
import { onError } from '~/components/helpers'
import Button from '~/components/ui/Button'
import useToast from '~/components/ui/Toast'
import { EmailRegex } from '~/constants'
import { personDetailQuery } from '~/inputs/person'

import { PersonDetails, PersonDetailsVariables } from '../inputs/__generated__/PersonDetails'
import { CreatePerson, CreatePersonVariables } from './__generated__/CreatePerson'
import { ListPeople } from './__generated__/ListPeople'
import { UpdatePerson, UpdatePersonVariables } from './__generated__/UpdatePerson'

/***
 *
 * Queries & Mutations
 *
 ***/
const createPersonMutation = gql`
	mutation CreatePerson($personData: createPersonInput!) {
		createPerson(input: $personData) {
			person {
				id
			}
		}
	}
`

export const updatePersonMutation = gql`
	mutation UpdatePerson($personData: updatePersonInput!) {
		updatePerson(input: $personData) {
			person {
				id
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	onAdd?: (formData: { person: string }) => Promise<void>
	inEditMode: boolean
	personId: string
	isOpen: boolean
	onClose: () => void
	onRefetch: (
		variables?: Partial<OperationVariables> | undefined
	) => Promise<ApolloQueryResult<ListPeople>>
}

/***
 *
 * Person View Component
 *
 ***/
const PersonView: FC<Props> = props => {
	const { inEditMode, isOpen, onClose, personId, onRefetch, onAdd } = props
	const toast = useToast()
	const [validationError, setValidationError] = useState<string | null>(null)

	const { register, handleSubmit, formState, control } = useForm<PersonInput>({ mode: 'onSubmit' })

	const [
		fetchPersonDetails,
		{ data: personDetails, loading: personDetailsLoading, refetch: refetchPersonDetails },
	] = useLazyQuery<PersonDetails, PersonDetailsVariables>(personDetailQuery, {
		variables: { personId },
	})

	const [addPerson] = useMutation<CreatePerson, CreatePersonVariables>(createPersonMutation, {
		onError: error => onError(error, toast),
	})

	const [updatePerson] = useMutation<UpdatePerson, UpdatePersonVariables>(updatePersonMutation, {
		onError: error => onError(error, toast),
	})

	const [deleteChatConversation] = useMutation<
		DeleteChatConversation,
		DeleteChatConversationVariables
	>(deleteChatConversationMutation, {
		onError: error => onError(error, toast),
	})

	const submitHandler = async (formData: PersonInput) =>
		inEditMode ? personUpdateHandler(formData) : addPersonHandler(formData)

	const personUpdateHandler = async (formData: PersonInput) => {
		const newFormData = formData
		if (
			formData.phone !== personDetails?.person?.phone &&
			personDetails?.person?.twilio_conversation_sid
		) {
			await deleteChatConversation({
				variables: { conversationSid: personDetails.person.twilio_conversation_sid },
			})
			newFormData.twilio_conversation_sid = null
		}
		const { errors } = await updatePerson({
			variables: { personData: { where: { id: personId }, data: newFormData } },
		})

		if (!errors) {
			await refetchPersonDetails?.()
			await onRefetch()

			onClose()
			toast({ title: 'Person details updated.', status: 'success', position: 'top-right' })
		}
	}

	const addPersonHandler = async (formData: PersonInput) => {
		const { data, errors } = await addPerson({ variables: { personData: { data: formData } } })

		const personId = data?.createPerson?.person?.id

		if (!errors && personId) {
			await onAdd?.({ person: personId })
			onClose()
		}
	}

	useEffect(() => {
		inEditMode && fetchPersonDetails()
	}, [inEditMode])

	const phoneUtil = PhoneNumberUtil.getInstance()

	const isPhoneValid = (phone: string) => {
		try {
			return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
		} catch (error) {
			return false
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='2xl'>
			<ModalOverlay backdropBlur='xl' />
			<ModalContent as='form'>
				<ModalHeader>{inEditMode ? 'Edit Person' : 'Add Person'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					{personDetailsLoading || (inEditMode && !personDetails?.person?.id) ? (
						<Stack spacing={5} w='full' pl={5} pr={10} mb={8} mt={3}>
							<Skeleton height='2em' rounded='md' />
							<Skeleton height='2em' rounded='md' />
							<Skeleton height='2em' rounded='md' />
						</Stack>
					) : (
						<Flex align='center' justify='space-between' gap='2em' flexWrap='wrap'>
							<FormControl w='18em' isRequired>
								<FormLabel>First Name</FormLabel>
								<Input
									{...register('first_name', { required: true })}
									defaultValue={personDetails?.person?.first_name}
									placeholder='Enter first name'
								/>
							</FormControl>

							<FormControl w='18em'>
								<FormLabel>Middle Name</FormLabel>
								<Input
									{...register('middle_name')}
									defaultValue={personDetails?.person?.middle_name ?? ''}
									placeholder='Enter middle name'
								/>
							</FormControl>

							<FormControl w='18em'>
								<FormLabel>Last Name</FormLabel>
								<Input
									{...register('last_name')}
									defaultValue={personDetails?.person?.last_name ?? ''}
									placeholder='Enter last name'
								/>
							</FormControl>

							<FormControl
								w='18em'
								isRequired
								sx={{
									'.react-international-phone-input': {
										width: '100%',
										height: '40px !important',
									},
									'.react-international-phone-country-selector-button': {
										height: '40px',
									},
								}}
							>
								<FormLabel>Phone</FormLabel>
								<Controller
									control={control}
									name='phone'
									defaultValue={personDetails?.person?.phone}
									rules={{ required: true }}
									render={({ field: { onChange, value } }) => (
										<PhoneInput
											defaultCountry='gb'
											{...register('phone')}
											required={true}
											value={value}
											onChange={onChange}
											onBlur={() => {
												if (value) {
													if (!isPhoneValid(value)) {
														setValidationError('Invalid phone format')
													}
												}
											}}
											onFocus={() => {
												setValidationError(null)
											}}
											placeholder='Phone'
										/>
									)}
								/>
								{validationError ? (
									<Text fontSize='sm' color='red.400' mt='0.5em'>
										{validationError}
									</Text>
								) : null}
							</FormControl>
							<FormControl w='18em' isRequired>
								<FormLabel>Email</FormLabel>
								<Input
									{...register('email', {
										required: true,
										pattern: EmailRegex,
									})}
									defaultValue={personDetails?.person?.email || ''}
									placeholder='Enter email'
								/>
								{formState?.errors?.email && (
									<Text fontSize='sm' color='red.400' mt='0.5em'>
										Please enter a valid email address.
									</Text>
								)}
							</FormControl>
						</Flex>
					)}
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose} mr={3} disabled={formState.isSubmitting}>
						Cancel
					</Button>
					<Button
						colorScheme='green'
						type='button'
						onClick={handleSubmit(submitHandler)} // Using handleSubmit on button to avoid event bubbling, as this is a nested form.
						isLoading={formState.isSubmitting}
						isDisabled={formState.isSubmitting || Boolean(validationError)}
					>
						{inEditMode ? 'Save' : 'Add'}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default PersonView
