import { Box, Flex, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

import React, { FC } from 'react'

import { FetchChatMessages_fetchChatMessages_messages_media } from '~/components/lead/components/chat/__generated__/FetchChatMessages'

interface Props {
	messageData: MessageType
	setOpenedSid: (sid: string | null) => void
}

export type MessageType = {
	id: string
	author: string
	body: string
	createdAt: string
	media: (FetchChatMessages_fetchChatMessages_messages_media | null)[] | null
}

const ChatMessage: FC<Props> = props => {
	const { messageData, setOpenedSid } = props
	const isMe = messageData.author.includes('Ruhdental')

	const renderMediaElement = (mediaItem: FetchChatMessages_fetchChatMessages_messages_media) => {
		if (!mediaItem.links?.content_direct_temporary) {
			return null
		}

		if (mediaItem.content_type?.includes('image')) {
			return (
				<Box
					sx={{ width: '240px', img: { width: '100%' } }}
					onClick={() => setOpenedSid(mediaItem.sid)}
				>
					<img src={mediaItem.links?.content_direct_temporary} alt='Media' width='100%' />
				</Box>
			)
		}
		if (mediaItem.content_type?.includes('video')) {
			return (
				<Box sx={{ width: '240px' }}>
					<video controls width='100%'>
						<source src={mediaItem.links?.content_direct_temporary} type={mediaItem.content_type} />
					</video>
				</Box>
			)
		}
		if (mediaItem.content_type?.includes('audio')) {
			return (
				<Box>
					<audio controls>
						<source src={mediaItem.links?.content_direct_temporary} type={mediaItem.content_type} />
					</audio>
				</Box>
			)
		}
		return null
	}

	const renderMessageContent = () => {
		if (messageData.media && messageData.media.length > 0) {
			return (
				<>
					<Text whiteSpace='pre-line' fontSize='0.8em' color='#111b21' textAlign='left'>
						{messageData.body}
					</Text>
					{messageData.media.map(media => {
						if (!media) return null
						return <React.Fragment key={media!.sid}>{renderMediaElement(media)}</React.Fragment>
					})}
				</>
			)
		}
		return (
			<Text whiteSpace='pre-line' fontSize='0.8em' color='#111b21' textAlign='left'>
				{messageData.body}
			</Text>
		)
	}

	return (
		<>
			<Box
				id={messageData.id}
				maxWidth='48%'
				width='auto'
				px={4}
				py={2}
				margin='5px'
				rounded='2xl'
				bg={isMe ? '#cffbcb' : '#fff'}
				borderBottomLeftRadius={isMe ? '2xl' : '0'}
				borderBottomRightRadius={isMe ? '0' : '2xl'}
				alignSelf={isMe ? 'end' : 'start'}
			>
				<Flex gap={1} direction='column' w='full'>
					<Flex gap='0.8em' align='center' fontWeight='bold' justify='space-between'>
						<Text fontSize='0.65em' color='#111b21'>
							{messageData.author}
						</Text>
						<Text fontSize='0.65em' fontWeight='bold' color='#111b21'>
							{format(new Date(messageData.createdAt), 'LLL dd y, HH:mm')}
						</Text>
					</Flex>
					{renderMessageContent()}
				</Flex>
			</Box>
		</>
	)
}

export default ChatMessage
