import { gql, useMutation, useQuery } from '@apollo/client'
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Spinner,
	Text,
} from '@chakra-ui/react'
import { PhoneNumberUtil } from 'google-libphonenumber'

import React, { useEffect, useRef } from 'react'

import { PersonsSearch_personsSearch } from '~/components/__generated__/PersonsSearch'
import ChatBody from '~/components/chat/ChatBody'
import ChatConversations from '~/components/chat/ChatConversations'
import { FetchChatConversations } from '~/components/chat/__generated__/FetchChatConversations'
import { onError } from '~/components/helpers'
import { startChatMutation } from '~/components/lead/components/chat/WhatsappContainer'
import {
	StartChat,
	StartChatVariables,
} from '~/components/lead/components/chat/__generated__/StartChat'
import useToast from '~/components/ui/Toast'
import { socket } from '~/context/SocketProvider'

export const fetchChatConversationsQuery = gql`
	query FetchChatConversations {
		fetchChatConversations {
			conversations {
				conversationSid
				friendlyName
				uniqueClinics {
					id
					name
				}
				person {
					id
					first_name
					middle_name
					last_name
					phone
					email
					twilio_conversation_sid
				}
				lastMessage {
					accountSid
					conversationSid
					sid
					index
					author
					body
					media {
						sid
						date_created
						links {
							content_direct_temporary
						}
						content_type
					}
					attributes
					participantSid
					dateCreated
					dateUpdated
					url
					contentSid
				}
			}
		}
	}
`

export const deleteChatConversationMutation = gql`
	mutation DeleteChatConversation($conversationSid: String!) {
		deleteChatConversation(conversationSid: $conversationSid) {
			success
		}
	}
`

interface ChatProps {
	onClose: () => void
	isOpen: boolean
	setShowNewMessages: (showMessages: boolean) => void
}

const Chat = ({ onClose, isOpen, setShowNewMessages }: ChatProps) => {
	const toast = useToast()
	const [chatLoading, setChatLoading] = React.useState(false)
	const [activeConversationId, setActiveConversationId] = React.useState<string | null>(null)
	const previousConversationsRef = useRef<
		FetchChatConversations['fetchChatConversations']['conversations'] | null
	>(null)

	const {
		data: conversations,
		loading,
		refetch,
	} = useQuery<FetchChatConversations>(fetchChatConversationsQuery, {
		onError: error => onError(error, toast),
	})

	const [startChat] = useMutation<StartChat, StartChatVariables>(startChatMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['PersonDetails'],
	})

	useEffect(() => {
		if (isOpen) {
			setChatLoading(true)
			refetch().finally(() => setChatLoading(false))
		}
	}, [isOpen])

	useEffect(() => {
		// Refetch conversations when the socket event fires
		socket.on('onConversationUpdated', () => {
			refetch().then(({ data }) => {
				if (previousConversationsRef.current && !isOpen) {
					const previousConversations = previousConversationsRef.current
					const currentConversations = data?.fetchChatConversations?.conversations

					// Compare previous and current conversations
					if (JSON.stringify(previousConversations) !== JSON.stringify(currentConversations)) {
						// Check if any conversation's lastMessage.author includes 'Ruhdental'
						const hasUsersMessages = currentConversations?.every(
							conversation => !conversation.lastMessage?.author.includes('Ruhdental')
						)

						// If none of the lastMessage authors include 'Ruhdental', store the info in localStorage
						if (hasUsersMessages) {
							setShowNewMessages(true)
						}
					}
				}

				// Update the previous conversations reference with the latest data
				previousConversationsRef.current = data?.fetchChatConversations?.conversations || null
			})
		})

		// Cleanup event listener when component unmounts
		return () => {
			socket.off('onConversationUpdated')
		}
	}, [refetch, isOpen])

	// Store the conversations in the ref on initial fetch
	useEffect(() => {
		if (!previousConversationsRef.current && conversations?.fetchChatConversations?.conversations) {
			previousConversationsRef.current = conversations.fetchChatConversations.conversations
		}
	}, [conversations])

	const phoneUtil = PhoneNumberUtil.getInstance()

	const isPhoneValid = (phone: string) => {
		try {
			return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
		} catch (error) {
			return false
		}
	}

	const handleOpenSearchPerson = async (person: PersonsSearch_personsSearch) => {
		if (person.twilio_conversation_sid) {
			setActiveConversationId(person.twilio_conversation_sid)
		} else {
			if (isPhoneValid(person.phone)) {
				setChatLoading(true)
				const response = await startChat({
					variables: {
						params: {
							friendlyName: 'New conversation with ' + person.first_name,
							personId: person.id,
						},
					},
				})
				await refetch()
				if (response.data?.startChat?.conversationSid) {
					setActiveConversationId(response.data.startChat.conversationSid)
				}
				setChatLoading(false)
			} else {
				toast({
					title: 'Invalid phone number',
					description: 'Please provide a valid phone number',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
			}
		}
	}

	const renderChatLoader = () => {
		if (!chatLoading) {
			return null
		}
		return (
			<Flex
				gap='1em'
				p='1em'
				sx={{
					position: 'absolute',
					zIndex: 20,
					background: 'rgba(255,255,255,0.7)',
					top: 0,
					alignItems: 'center',
					justifyContent: 'center',
					left: 0,
					width: '100%',
					height: '100%',
				}}
			>
				<Spinner color='gray.500' />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)
	}

	return (
		<Drawer placement='right' onClose={onClose} isOpen={isOpen} size='full'>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerBody sx={{ padding: 0, position: 'relative' }}>
					<Box sx={{ height: '100%', display: 'flex', justifyContent: 'space-between' }}>
						{renderChatLoader()}
						<ChatConversations
							onOpenSearchPerson={handleOpenSearchPerson}
							activeConversationId={activeConversationId}
							setActiveConversationId={setActiveConversationId}
							conversations={conversations?.fetchChatConversations?.conversations}
							loading={loading}
							setChatLoading={setChatLoading}
						/>
						<ChatBody
							onClose={() => {
								setActiveConversationId(null)
								onClose()
							}}
							conversationsLoading={loading}
							conversationData={conversations?.fetchChatConversations?.conversations.find(
								conversation => conversation.conversationSid === activeConversationId
							)}
						/>
					</Box>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	)
}

export default Chat
