import { useMutation, useQuery } from '@apollo/client'
import { Avatar, Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import moment from 'moment/moment'
import { IoClose } from 'react-icons/io5'
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

import React, { useContext, useEffect, useRef } from 'react'

import ChatInput from '~/components/chat/ChatInput'
import ChatMessage from '~/components/chat/ChatMessage'
import { FetchChatConversations_fetchChatConversations_conversations } from '~/components/chat/__generated__/FetchChatConversations'
import { onError } from '~/components/helpers'
import {
	fetchChatMessagesMutation,
	getChatTemplatesQuery,
	sendChatMessageMutation,
} from '~/components/lead/components/chat/WhatsappContainer'
import {
	FetchChatMessages,
	FetchChatMessages_fetchChatMessages_messages,
	FetchChatMessagesVariables,
} from '~/components/lead/components/chat/__generated__/FetchChatMessages'
import {
	GetChatTemplates,
	GetChatTemplatesVariables,
} from '~/components/lead/components/chat/__generated__/GetChatTemplates'
import {
	SendChatMessage,
	SendChatMessageVariables,
} from '~/components/lead/components/chat/__generated__/SendChatMessage'
import { AuthContext } from '~/components/page/context'
import useToast from '~/components/ui/Toast'

import { sendChatMessageInput } from '../../../__generated__/globalTypes'

interface ChatBodyProps {
	onClose: () => void
	conversationData: FetchChatConversations_fetchChatConversations_conversations | undefined
	conversationsLoading: boolean
}

const ChatBody = ({ onClose, conversationData, conversationsLoading }: ChatBodyProps) => {
	const [messages, setMessages] = React.useState<FetchChatMessages_fetchChatMessages_messages[]>([])
	const [showTemplates, setShowTemplates] = React.useState<boolean>(false)
	const [openedSid, setOpenedSid] = React.useState<string | null>(null)

	// Ref to the container for scrolling
	const messagesEndRef = useRef<HTMLDivElement>(null)

	const toast = useToast()
	const { data: chatTemplates, loading: chatTemplatesLoading } = useQuery<
		GetChatTemplates,
		GetChatTemplatesVariables
	>(getChatTemplatesQuery, {
		variables: {
			params: {
				limit: 100,
			},
		},
	})

	const [sendChatMessage, { loading: sendChatMessageLoading }] = useMutation<
		SendChatMessage,
		SendChatMessageVariables
	>(sendChatMessageMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['PersonDetails', 'FetchChatConversations'],
	})

	const [fetchChatMessages, { loading: fetchChatMessagesLoading }] = useMutation<
		FetchChatMessages,
		FetchChatMessagesVariables
	>(fetchChatMessagesMutation, {
		onError: error => onError(error, toast),
	})

	const user = useContext(AuthContext)

	// Auto-scroll to the bottom when new messages are fetched
	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	useEffect(() => {
		if (conversationData?.conversationSid) {
			const fetcher = async () => {
				await fetchChatMessages({
					variables: {
						conversationSid: conversationData?.conversationSid as string,
					},
				}).then(res => {
					if (res?.data?.fetchChatMessages.messages) {
						setMessages(res.data.fetchChatMessages.messages)
					}
				})
			}
			fetcher()
		}
	}, [conversationData])

	// Trigger scroll to bottom whenever the messages array changes
	useEffect(() => {
		if (messages.length > 0) {
			scrollToBottom()
		}
	}, [messages])

	const getAllMessagesImages = () => {
		return messages.reduce((acc, message) => {
			if (message.media) {
				message.media.forEach(mediaItem => {
					if (
						mediaItem?.content_type?.includes('image') &&
						mediaItem.links?.content_direct_temporary
					) {
						acc.push({
							src: mediaItem.links?.content_direct_temporary,
							id: mediaItem?.sid as string,
						})
					}
				})
			}
			return acc
		}, [] as { src: string; id: string }[])
	}

	if (!conversationData) {
		return (
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					height: '100vh',
				}}
			>
				<Box
					sx={{
						minHeight: '60px',
						backgroundColor: '#fff',
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'flex-end',
						padding: '0 12px',
					}}
				>
					<Button
						leftIcon={<IoClose fontSize='18px' color='#546570' />}
						sx={{
							width: '20px',
							padding: 0,
							span: {
								width: '100%',
								margin: 0,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							},
						}}
						size='xs'
						_hover={{ bg: '#e2e2e2' }}
						onClick={onClose}
					/>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexGrow: 1,
						flexDirection: 'column',
						background: '#fff',
						gap: 5,
						padding: '0 15px',
					}}
				>
					<Text
						sx={{
							color: 'gray.500',
						}}
					>
						Choose user and start your conversation
					</Text>
				</Box>
			</Box>
		)
	}

	const handleSendMessage = async (
		message?: string,
		contentSid?: string,
		contentVariables?: { [key: string]: string }
	) => {
		const sendMessageParams: sendChatMessageInput = {
			conversationSid: conversationData.conversationSid as string,
			authorName: user.username + ' Ruhdental',
		}
		if (contentSid) {
			sendMessageParams.contentSid = contentSid
		}
		if (contentVariables) {
			sendMessageParams.contentVariables = contentVariables
		}
		if (message) {
			sendMessageParams.message = message
		}
		setShowTemplates(false)
		await sendChatMessage({
			variables: {
				params: sendMessageParams,
			},
		}).then(async () => {
			await fetchChatMessages({
				variables: {
					conversationSid: conversationData?.conversationSid as string,
				},
			}).then(res => {
				if (res?.data?.fetchChatMessages.messages) {
					setMessages(res.data.fetchChatMessages.messages)
				}
			})
		})
	}

	const renderTemplatesPicker = () => {
		if (chatTemplatesLoading) {
			return (
				<Flex justify='center' gap='1em' p='1em' width='100%'>
					<Spinner />
					<Text color='gray.500' fontSize='sm'>
						Loading
					</Text>
				</Flex>
			)
		}

		const onSendMessage = async (sid: string) => {
			await handleSendMessage('', sid)
		}

		return (
			<Box
				mb={6}
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				{chatTemplates?.getChatTemplates.templates?.map(template => {
					return (
						<Box
							key={template.sid}
							onClick={() => onSendMessage(template.sid as string)}
							p={2}
							mx={2}
							my={1}
							background='#fff'
							borderRadius='8px'
							display='inline-flex'
							width='auto'
							_hover={{ cursor: 'pointer', background: '#f8f8f8' }}
						>
							<Text
								width='auto'
								display='inline-flex'
								color='gray.500'
								fontSize='13px'
								textAlign='left'
							>
								{template.types['twilio/text'].body}
							</Text>
						</Box>
					)
				})}
			</Box>
		)
	}

	const renderChatContent = () => {
		const messageImages = getAllMessagesImages()
		return (
			<Box
				w='100%'
				gap={2}
				display='flex'
				flexDirection='column'
				paddingBottom='40px'
				position='relative'
			>
				<Button
					onClick={() => setShowTemplates(!showTemplates)}
					sx={{
						width: '130px',
						position: 'sticky',
						fontSize: '14px',
						fontWeight: 500,
						background: '#fff',
						marginTop: '5px',
						top: '5px',
						borderRadius: '35px',
						zIndex: 20,
						left: 'calc(50% - 65px)',
					}}
				>
					{showTemplates ? 'Hide' : 'Show'} templates
				</Button>
				{showTemplates ? (
					<Box
						sx={{
							padding: '10px 20px',
						}}
					>
						{renderTemplatesPicker()}
					</Box>
				) : (
					messages.map(message => {
						return (
							<ChatMessage
								setOpenedSid={setOpenedSid}
								key={message.sid}
								messageData={{
									id: message.sid,
									author: message.author.includes('Ruhdental')
										? message.author
										: conversationData?.person?.first_name || 'N/A',
									body: message.body as string,
									createdAt: moment(Number(message.dateCreated)).toISOString(),
									media: message.media,
								}}
							/>
						)
					})
				)}
				<Lightbox
					open={Boolean(openedSid)}
					close={() => setOpenedSid(null)}
					slides={messageImages}
					index={messageImages.findIndex(image => image.id === openedSid)}
					plugins={[Thumbnails]}
				/>
				{/* This is the reference div for auto-scrolling */}
				<div ref={messagesEndRef} />
			</Box>
		)
	}

	const isLoading =
		(fetchChatMessagesLoading && !messages.length) || (conversationsLoading && !messages.length)

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100vh',
			}}
		>
			<Box
				sx={{
					minHeight: '60px',
					backgroundColor: '#f0f2f5',
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'space-between',
					padding: '0 12px',
				}}
			>
				<Box
					onClick={
						conversationData.person?.id
							? () => navigate('/person/' + conversationData.person?.id)
							: () => {}
					}
					sx={{
						cursor: conversationData.person?.id ? 'pointer' : 'default',
						display: 'flex',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<Avatar
						w='49px'
						h='49px'
						name={
							conversationData.person
								? `${conversationData.person?.first_name} ${conversationData.person?.last_name}`
								: 'N/A'
						}
						border='4px solid #EEAB7E'
					/>
					<Box sx={{ display: 'flex', marginLeft: '8px', alignItems: 'center' }}>
						<Text fontSize='1em' color='#111b21' fontWeight={500} p={0}>
							{conversationData.person
								? `${conversationData.person?.first_name} ${conversationData.person?.last_name}`
								: 'N/A'}
						</Text>
					</Box>
				</Box>
				<Button
					leftIcon={<IoClose fontSize='18px' color='#546570' />}
					sx={{
						width: '20px',
						padding: 0,
						span: {
							width: '100%',
							margin: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						},
					}}
					size='xs'
					_hover={{ bg: '#e2e2e2' }}
					onClick={onClose}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexGrow: 1,
					flexDirection: 'column',
					overflowY: 'auto',
					background: '#dedede',
					gap: 5,
					padding: '0 15px',
				}}
			>
				{isLoading ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
							width: '100%',
							height: '100%',
						}}
					>
						<Spinner color='gray.500' />
						<Text color='gray.500' fontSize='sm'>
							Loading
						</Text>
					</Box>
				) : (
					renderChatContent()
				)}
			</Box>
			<Box
				sx={{
					minHeight: '60px',
					backgroundColor: '#f0f2f5',
					alignItems: 'center',
					display: 'flex',
					width: '100%',
					padding: '0 12px',
				}}
			>
				<ChatInput
					isLoading={sendChatMessageLoading}
					onSend={handleSendMessage}
					isDisabled={!messages.length}
				/>
			</Box>
		</Box>
	)
}

export default ChatBody
