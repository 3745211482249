import { gql, useQuery, useMutation } from '@apollo/client'
import { Box, Flex, Tag, Text, VStack } from '@chakra-ui/react'
import { BsChat } from 'react-icons/bs'
import { FaSyncAlt } from 'react-icons/fa'

import React, { FC, useContext, useState, useRef, useEffect } from 'react'

import { setKanbanRefetchVal } from '~/helpers'
import { FindLead_findLead_person } from '~/views/__generated__/FindLead'

import { joinValues, onError } from '../../../helpers'
import { AuthContext } from '../../../page/context'
import Button from '../../../ui/Button'
import useToast from '../../../ui/Toast'
import Message, { MessageType, MessageSkeleton } from './Message'
import MessageInput from './MessageInput'
import { PersonMessages, PersonMessagesVariables } from './__generated__/PersonMessages'
import { SendMessage, SendMessageVariables } from './__generated__/SendMessage'
import { UpdateUnreadMsg, UpdateUnreadMsgVariables } from './__generated__/UpdateUnreadMsg'

/***
 *
 * Queries & Mutations
 *
 ***/
const getPersonMessagesQuery = gql`
	query PersonMessages($to: String) {
		messages(to: $to) {
			id
			from
			to
			body
			created_at
			isReply
		}
	}
`

const sendMsgMutation = gql`
	mutation SendMessage($message: sendMessageInput!) {
		sendMessage(message: $message) {
			from
			to
			body
			created_at
		}
	}
`

export const updateUnreadMsgMutation = gql`
	mutation UpdateUnreadMsg($data: updateLeadInput!) {
		updateLead(input: $data) {
			lead {
				id
			}
		}
	}
`

/***
 *
 * Interface & Type
 *
 ***/
interface Props {
	person: FindLead_findLead_person
	unreadMessageId?: string
	hasUnreadMessage?: boolean
	leadId?: string
	onRefetch: () => Promise<void>
}

/***
 *
 * Chat Container Component
 *
 ***/
const ChatContainer: FC<Props> = props => {
	const { person, unreadMessageId, hasUnreadMessage, leadId, onRefetch } = props
	const user = useContext(AuthContext)
	const toast = useToast()
	const personName = joinValues(person?.first_name)
	const msgListRef = useRef<HTMLDivElement>(null)

	const [isSending, setIsSending] = useState(false)

	const { data, refetch, loading } = useQuery<PersonMessages, PersonMessagesVariables>(
		getPersonMessagesQuery,
		{
			variables: { to: person.phone },
			notifyOnNetworkStatusChange: true,
		}
	)

	const [sendMessage] = useMutation<SendMessage, SendMessageVariables>(sendMsgMutation, {
		onError: error => onError(error, toast),
	})

	const [updateUnreadMsg] = useMutation<UpdateUnreadMsg, UpdateUnreadMsgVariables>(
		updateUnreadMsgMutation,
		{
			onError: error => onError(error, toast),
		}
	)

	const messageList = data?.messages.filter(Boolean).map(message => ({
		id: message?.id,
		author: message?.isReply ? personName : user.username,
		body: message?.body,
		createdAt: message?.created_at,
		isReply: message?.isReply,
	})) as MessageType[]

	const msgListFragment = !messageList?.length ? (
		<Flex w='full' justify='center' alignSelf='center' mt={10}>
			<Text color='#64554B' fontSize='16px' fontWeight='700' opacity='0.4' textAlign='center'>
				No conversation yet
			</Text>
		</Flex>
	) : (
		messageList?.map(message => (
			<Message
				key={message.id}
				isUnread={hasUnreadMessage && unreadMessageId === message.id}
				messageData={message}
			/>
		))
	)

	const handleOnSend = async (messageBody: string) => {
		if (!leadId) return

		setIsSending(true)

		const { errors } = await sendMessage({
			variables: { message: { to: person.phone, body: messageBody, leadId, owner: user.id } },
		})

		hasUnreadMessage && (await handleUpdateUnreadMsg())

		if (!errors) {
			await refetch()
			scrollChatContainer()
			toast({ title: 'Message sent.', status: 'success', position: 'top-right' })
		}

		setIsSending(false)
	}

	const handleUpdateUnreadMsg = async () => {
		if (!leadId) return

		await updateUnreadMsg({
			variables: {
				data: {
					where: { id: leadId },
					data: { has_unread_message: false, unread_message_id: null },
				},
			},
		})

		await onRefetch()
		setKanbanRefetchVal('true')
	}

	const handleRefetch = async () => {
		await refetch()
		scrollChatContainer()
	}

	const handleScroll = async (evt: React.UIEvent<HTMLDivElement, UIEvent>) => {
		evt.currentTarget.offsetHeight + evt.currentTarget.scrollTop >= evt.currentTarget.scrollHeight
	}

	const scrollChatContainer = (messageId?: string) => {
		if (messageId) {
			const messageDiv = document.getElementById(messageId)
			messageDiv?.scrollIntoView()
			return
		}

		const scrollHeight = msgListRef?.current?.scrollHeight ?? 0
		const height = msgListRef?.current?.clientHeight ?? 0
		const maxScrollTop = scrollHeight - height

		if (msgListRef?.current?.scrollTo) {
			msgListRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
		}
	}

	useEffect(() => {
		scrollChatContainer(unreadMessageId)
	}, [loading])

	return (
		<Box
			w='full'
			border='1px solid rgba(100, 85, 75, 0.2)'
			borderRadius='8px'
			padding='10px 20px 20px 20px'
		>
			<Box w='full' bg='white' rounded='md'>
				<Flex align='center' gap={2} w='full' rounded='md' pb={2}>
					<BsChat color='#EEAB7E' fontSize={20} />
					<Text fontWeight='bold' color='#64554B'>
						Chat with {joinValues(person?.first_name, person?.middle_name, person?.last_name)}
					</Text>
					<Button
						ml='auto'
						title='Refresh'
						aria-label='Refresh'
						leftIcon={<FaSyncAlt color='#fff' />}
						size='sm'
						bg='#EEAB7E'
						isDisabled={loading}
						onClick={handleRefetch}
					/>
				</Flex>
				<Box mt={2}>
					<VStack
						onScroll={handleScroll}
						ref={msgListRef}
						bg='#f8f8f8'
						borderRadius='8px'
						h='18em'
						overflowY='auto'
						gap={5}
						align='start'
						w='full'
						mb={3}
						p={4}
						pb={0}
					>
						{loading && !isSending ? <MessageSkeleton /> : msgListFragment}

						{!loading && hasUnreadMessage && (
							<Tag
								rounded='full'
								px='1em'
								colorScheme='orange'
								alignSelf='center'
								cursor='pointer'
								onClick={handleUpdateUnreadMsg}
							>
								Mark as read
							</Tag>
						)}
					</VStack>
					<Box pt={3}>
						<MessageInput isLoading={loading} onSend={handleOnSend} />
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default ChatContainer
